
import global from '../../global.js';
import * as tools from '../../tools.js';
import t from '../../translate.js';
import * as db from './data.js';
import mapHelper from '../../core/map.js';
import * as fs_lib from './fairshare.js';
import ComponentModule from 'framework7/modules/component/component.js';
import { curveCatmullRomOpen } from 'd3v4';
import * as d3 from "d3v4";
import * as c3 from 'c3';
import 'c3/c3.css';




export function home_trap(id_farm_center, id_season){
  jQuery('#footer').html('');


  var farm_data=db.getFarmDataSeason(id_farm_center,id_season);

  global.theme.render({
    exclude_card:true,
    title: t(`Trappole`),
    content: `
      <div class="block-title">${t("Elenco delle trappole")}</div>
      <div style="margin:10px;">
        <button onclick="location.hash='farm/${id_farm_center}/season/${id_season}/trap/add_trap'" class="button fs-button button-large button-fill">${t("Aggiungi una trappola")}</button>
      </div>
      <div class="list media-list inset"><ul id="trap_list"></ul></div>

      <div  id="trap_chart" class="list media-list inset"></div>
    `
  });


  if(farm_data.trap.length==0){
    jQuery("trap_list").html(`<div class="block-title">${t("Nessuna trappola presente")}</div>`);
  }
  else{
    var tr="";
    var traps={};

    jQuery.each(farm_data.trap, function(i,v){


      traps[v.id_trap]=v;
      traps[v.id_trap].observation=[];

      var link=`#farm/${id_farm_center}/season/${id_season}/trap/${v.id_trap}`;

      tr+=`
      <li>
        <a href="${link}" class="item-link link external item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title">${v.name}</div>
            </div>
            <div class="item-subtitle">${db.decodePest(v.pest)}</div>
          </div>
        </a>
      </li>
        `;
    });
    jQuery("#trap_list").html(tr);


    //Aggiunge alle trappole le osservazioi
    jQuery.each(farm_data.observation, function(k,o){
      if(o.id_survey_schema==1 && o.id_trap){
        traps[o.id_trap].observation.push(o);
      }
    });


    var xs={};
    var columns=[];
    var names={};

    jQuery.each(traps, function(k,v){

      xs['val_'+k]='data_'+k;
      names['val_'+k]=v.name;

      var ddd=['data_'+k];
      var vvv=['val_'+k];

      jQuery.each(v.observation, function(ko,o){
       
        ddd.push(new Date(o.date).getTime());
        vvv.push(o.trap);

      });

      columns.push(ddd);
      columns.push(vvv);
    });


    console.log(xs);
    console.log(columns);

    // xs: {
    //   'data1': 'x1',
    //   'data2': 'x2',
    // },
    // columns: [
    //     ['x1', 10, 30, 45, 50, 70, 100],
    //     ['x2', 30, 50, 75, 100, 120],
    //     ['data1', 30, 200, 100, 400, 150, 250],
    //     ['data2', 20, 180, 240, 100, 190]
    // ]
    var chart = c3.generate({
      bindto: '#trap_chart',
      padding: {
        right: 40,
      },
      data: {
        xs:xs,
        columns:columns,
        names:names
      },
      subchart: {
        show: false
      },
      axis : {
        x : {
          type : 'timeseries',
          tick: {
            count:5,
            format: '%d/%m/%Y'
          },
        }        
      }
    });

  }
 
}


export function add_trap(id_farm_center, id_season){
  edit_trap(id_farm_center, id_season, null);
}

export function edit_trap(id_farm_center, id_season, id_trap){


  jQuery('#footer').html(`<button id="save_trap" class="button fs-button button-large button-fill">${t("Salva")}</button>`);
  var existing_trap=null;

  var farm_data=db.getFarmDataSeason(id_farm_center, id_season);
  var farm=farm_data.farm_center[0];
  var title=t("Aggiungi Trappola");

  if(id_trap){
    var ttt=farm_data.trap.filter(function(f){return f.id_trap==id_trap});
    if(ttt.length>0){
      existing_trap=ttt[0];
      title=t("Trappola")+" "+existing_trap.name;
    }
  }

  var map=new mapHelper();

  //serve u field a cui associare la trappola. Prende il primo
  var id_field=farm_data.field[0].id_field;

  global.theme.render({
    exclude_card:true,
    title: title,
    content: `
      
    ${existing_trap?`
    <div class="card">
      <a onclick="location.hash='farm/${id_farm_center}/field/${id_field}/new_observation/1?id_trap=${id_trap}'" ><i 
        class="icon f7-icons">plus_rounded
      </i>${t("Aggiungi dato")}</a>
      <table id="trap_data" class="data-table">
        <thead><tr><th>&nbsp;</th><th>${t("Data")}</th><th>${t("Catture")}</th></tr></thead>
        <tbody></tbody>
      </table>
      <button id="delete_trap" style="display:none" class="button button-large"><i class="f7-icons">trash</i>${t("Elimina la trappola")}</button>
    </div>
    <div class="block-title">${t("Modifica la trappola")}</div>
    `:``}

      <div class="card">
        <div id="trap_form"></div>
      </div>
      <div class="card demo-card-header-pic" style="border-radius: 15px; margin-left:15px; margin-right:15px;">
        ${map.getHtml(false,false)}
      </div>

      
    `
  }); 





    var aForm={
      "fields":{
        "name": {"label": t("Nome"), "type": "text", "nullable": 0},
        "date_trap": {"label": t("Data di positionamento"), "type": "date", "widget":"date", "nullable": 0},
        "type": {"label": t("Tipo di trappola"), "widget": "select", "voc_val":{
          "cromo": t("Cromo trappole"),        
          "pheno": t("Trappole a feromoni"),        
          "flytrap": t("FlyTrap"),        
          "other": t("Altro")
        }},          
        "pest": {"label": t("Insetto"), "widget": "select", "voc_val":{}        
        }, 
        "notes":{"label": t("Note")}     
      }
    };


  var pests=db.getPest();

  jQuery.each(pests, function(i,v){
    aForm.fields.pest.voc_val[v.eppo]=v.name;
  });


  map.initMap([farm.lat,farm.lon],12, false, {fullscreenControl: true});
  fs_lib.drawPolygon(farm_data, null,{skip_tooltip:true});

  var marker=null;
  if(existing_trap){
    marker=L.marker([existing_trap.lat, existing_trap.lon]);
    global.map.addLayer(marker);    


   
    var num_observation=0;
    farm_data.observation.forEach(function(f){
      if(f.id_survey_schema==1 && f.id_trap==existing_trap.id_trap){
        num_observation++;

        var tr=jQuery(`<tr>
          <td>
            <i 
              onclick="location.hash='farm/${id_farm_center}/field/${f.id_field}/observation/${f.id_observation}'" 
                class="icon f7-icons">pencil
            </i>
            <i class="to-delete icon f7-icons">trash</i>
          </td><td>${f.date}</td><td>${f.trap}</td><td></td></tr>`
        );

        jQuery("#trap_data tbody").append(tr);
        tr.find('.to-delete').click(function(){
          global.app.dialog.confirm(t("Sei sicuro di eliminare il record?"), function(){
            db.deleteElement("observation", f.id_observation, function(){
              edit_trap(id_farm_center, id_season, id_trap)
            });
          });
        });


      };
    });


    if(num_observation==0){
      jQuery("#delete_trap").show().on("click", function(){
        global.app.dialog.confirm(t("Sei sicuro di eliminare il record?"), function(){
          db.deleteTrap(id_trap);
          location.hash='farm/'+id_farm_center+'/season/'+id_season+'/trap/';
        });
      });


    }
  }

  global.map.on("click", function(e){
    if(marker){
      global.map.removeLayer(marker);
    }
    var latlng=e.latlng;
    marker = L.marker(latlng)
    global.map.addLayer(marker);
  });
      

  var form=new Dbmng.Form({aForm:aForm, aParam:{exclude_card:true} });
  jQuery('#trap_form').html(form.createForm(existing_trap));


  jQuery('#save_trap').click(function(){

    console.log(form);

    var valid=form.isValid();

    if(!marker){
      alert("Si prega di defire il punto cliccando sulla mappa");
    }
    else if(valid.ok){
      var val=form.getValue();
      val.lat=marker.getLatLng().lat;
      val.lon=marker.getLatLng().lng;
      val.id_farm_center=id_farm_center;
      val.id_season=id_season;
 
      if(existing_trap){
        db.updateTrap(val, existing_trap.id_trap);        
      }
      else{
        db.insertTrap(val);
      }


      history.back();
    }
    else{
      alert(valid.message);
    }

    


  });


}