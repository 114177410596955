

import global from '../../global.js';
import storage from '../../storage.js';
import * as tools from '../../tools.js';
import * as db from './data.js';
import t from '../../translate.js';

//import per la 1.7.3
import * as io from "socket.io-client";
import notification from '../../core/notification.js';
//import per le versioni recenti n(ma non hva il server)

function syncChat(){
  var s=storage.get();

  jQuery.ajax({
    contentType: "application/json; charset=utf-8",
    type: 'POST',
    async:false,
    url: global.chat_url+"api/v1/poderi/messages/home",
    data:JSON.stringify({
      uid: s.user.uid
    }),
    dataType: "json",
    success: function(res){
      global.chat_to_sync=false;

      console.log(res);

      if(res.chat_messages.data.length>0){
        var all_groups={};
        jQuery.each(res.chat_messages.data,function(k,v){
          if (!all_groups[v.id_group]) {
            console.log(v);
            all_groups[v.id_group]=v
          }
        });

        console.log(all_groups);
        s.chat={
          messages:res.chat_messages.data,
          groups:res.chat_root.data,
          empty_groups:res.empty_chat.data
        };
        storage.save(s);
      }
    }
  });

}


export function home(id_group){
  // debugger
  if(typeof global.chat_to_sync=='undefined' || global.chat_to_sync){
    syncChat();
  }

  var s=storage.get();
  var room_type='farm';

  if (s.chat && s.chat.groups) {
    var aGorup=s.chat.groups.filter(function(f){return f.id_group==id_group})
    var group='';
    if (aGorup.length>0) {
      group=aGorup[0].name;
      room_type=aGorup[0].type;
    }
  }

  console.log(group);
  jQuery('.title').html(group);

  global.theme.render({
    "title": "Chat",

    "content": `
        <div class="page-content messages-content">
          <div id="chat_timeline" class="messages">
          </div>
        </div>

        <div class="toolbar messagebar" style="position:fixed;">
          <div class="toolbar-inner">
            <div class="messagebar-area">
              <textarea id="room_message" class="resizable" placeholder="Message"></textarea>
            </div>
            <a class="link send-link" id="send_room">Send</a>
          </div>
        </div>

    `,
    exclude_card:true
  });

  jQuery('.toolbar-bottom').hide();

  jQuery("#f7").addClass("messages-content");

  global.use_socket=false;


  var messages = global.app.messages.create({
    el: '.messages',
  });



  // Init Messagebar
  var messagebar = global.app.messagebar.create({
    el: '.messagebar'
  });

  // var room_name="beaf1d99-d632-230b-ea45-359405d71322";
  var room_name=id_group;


  if(global.use_socket){
    //Attenzione. con l'esempio su template va usato il client 1.7.3
    const socket = io(global.chat_url);
    console.log("IO",io.protocol);


    


    jQuery('.left').click(function(){
      console.log("disconnect....");
      socket.disconnect();
    });

    //messaggio di connessione
    socket.on('connect', function(){

      socket.emit('send_user_data', { user: s.user });
      console.log("Connect");
      jQuery("#chat_timeline").append(`<div class="messages-title">Mi sono connesso ${new Date().toISOString()}</div>`);
    });

    //messaggio di errore
    socket.on("connect_error", (err) => {
      jQuery("#chat_timeline").append(`<div class="messages-title">Attenzione! Disconnesso dal server!</div>`);
    });


    socket.on("error", (err) => {
      console.log(err);
      alert("Errore nella CHAT: "+err.message);
    });
  }

  var last_message_name=null;
  if (s.chat) {
    console.log(
      s.chat.messages.filter(
        function(f){
          return f.id_group==id_group
        }
      )
    );
    jQuery.each(s.chat.messages.filter(function(f){return f.id_group==id_group}).reverse(),function(km,msg){
      drawMessage(msg,last_message_name);
      last_message_name=msg.username;

      //Se il messaggio non è stato letto e non è mio allora emetto un evento di lettura
      if (msg.is_read==0 && msg.sender_uid!=s.user.uid) {

        if(global.use_socket){
          socket.emit('read_message_room', 
            { 
              room: room_name, 
              id_message: msg.id_message ,
              access_token: s.user.access_token
            }
          );
        }
        else{
          jQuery.ajax({
            url: global.chat_url+"api/v1/poderi/messages/read_message_room",
            type: 'POST',
            headers: {
              'Authorization': 'Bearer '+s.user.access_token,
              "Content-Type": "application/json",
            },
            contentType: "application/json",
            data: JSON.stringify({
              room: room_name, 
              id_message:msg.id_message
            }),
            success: function(msg){
              console.log(msg);
            }
          });
        }
      }

    });
  }


  if(global.use_socket){
    //Risposta dopo che si è unito alla chat
    socket.on('join', function(msg){

      if(msg.username==s.user.username){
        jQuery("#chat_timeline").append(`<div class="messages-title">Sono entrato nella chat</div>`);
      }
      else{
        jQuery("#chat_timeline").append(`<div class="messages-title">E' appena entrato ${msg.username}</div>`);
      }
      //send_me_unreaded_messages
      //per adesso è disabilitato (cvecchio nome unread)
      //socket.emit('send_me_unreaded_messages', {room: room_name, username: s.user.username });
    });

    socket.emit('join', {room: room_name, username: s.user.username });
  }


  // INVIA IL MESSAGGIO
  jQuery("#send_room").click(function(){
    if (jQuery("#room_message").val()!='') {
      console.log("emit");
      var message_obj={
        room: room_name,
        type:room_type,
        id_message: tools.getGuid(),
        message: jQuery("#room_message").val(),
        username: s.user.username,
        sender_uid: s.user.uid,
        recipient_uid: aGorup[0].recipient_uid,
        access_token: s.user.access_token
      };

      if(global.use_socket ){
        socket.emit('send_message_room', message_obj);
      }
      else{
        jQuery.ajax({
          url: global.chat_url+"api/v1/poderi/messages/send_message_room",
          type: 'POST',
          headers: {
            'Authorization': 'Bearer '+s.user.access_token,
            "Content-Type": "application/json",
          },
          contentType: "application/json",
          data: JSON.stringify(message_obj),
          success: function(msg){
            console.log(msg);
            receiveMessageFromServer(msg,id_group);
          }
        });
      }

      var temporary=true;
      drawMessage(message_obj,last_message_name, temporary);

      // console.log(aGorup[0].recipient_uid);
      // debugger
      jQuery("#room_message").val(``);
    }
  });

  global.last_message_name=null;


  if(global.use_socket){
    //Risposta all'evento stanza
    socket.on('message_sent_to_server', function(msg){
      console.log("message_sent_to_server",msg);

      receiveMessageFromServer(msg,id_group);

    });

  }

}

export function notification_received(nn){

  console.log("notification_received",nn);

  //controlla se sono in chat
  if(location.hash=="#chat"){
    location.reload();
    
  }
  //if starst with chat
  else if(location.hash.startsWith("#chat")){


    //controlla se sei nella chat giusta
    let group_id=location.hash.split("/")[1];

    let s=storage.get();

    let uid=s.user.uid;
    let suid=null;
    if(nn && nn.data && nn.data.sender_uid){
      suid=nn.data.sender_uid;
    }
    else if(nn && nn.notification && nn.notification.sender_uid){
      suid=nn.notification.sender_uid;
    }
    else if(nn && nn.sender_uid){
      suid=nn.sender_uid;
    }


    if(group_id==uid+"_"+suid || group_id==suid+"_"+uid){
      //prendo i dati

      var username='';
      if(nn && nn.data && nn.data.sender_mail){
        username=nn.data.sender_mail;
      }
      else if(nn && nn.notification && nn.notification.sender_mail){
        username=nn.notification.sender_mail;
      }
      else if(nn && nn.sender_mail){
        username=nn.sender_mail;
      }

      var body='';
      if(nn && nn.data && nn.data.body){
        body=nn.data.body;
      }
      else if(nn && nn.notification && nn.notification.body){
        body=nn.notification.body;
      }
      else if(nn && nn.body){
        body=nn.body;
      }

      var id_message='';
      if(nn && nn.data && nn.data.id_message){
        id_message=nn.data.id_message;
      }
      else if(nn && nn.notification && nn.notification.id_message){
        id_message=nn.notification.id_message;
      }
      else if(nn && nn.id_message){
        id_message=nn.id_message;
      }

      drawMessage({
        "username": username,
        "id_message": id_message,
        "message": body,
      },username);

      // read_message_room
      jQuery.ajax({
        url: global.chat_url+"api/v1/poderi/messages/read_message_room",
        type: 'POST',
        headers: {
          'Authorization': 'Bearer '+s.user.access_token,
          "Content-Type": "application/json",
        },
        contentType: "application/json",
        data: JSON.stringify({
          room: group_id, 
          id_message:id_message
        }),
        success: function(msg){
          console.log(msg);
        }
      });
    }
  }
  else{
    console.log(nn);    
  }
}

function receiveMessageFromServer(msg,id_group){
  if(msg.error){
    alert(msg.error);
  }
  else{

    var s=storage.get();

    msg.id_group=id_group
    msg.is_read=1;
    msg.message_body=msg.message;
    msg.name=msg.username;
    msg.timestmp=new Date();

    s.chat.messages=prepend(msg,s.chat.messages);
    storage.save(s);

    console.log("NUOVO MESSAGGIO DA SALVARE",msg);
    console.log("new array...",s.chat.messages);
    drawMessage(msg,global.last_message_name);

    if(global.use_socket){
      socket.emit('read_message_room', {
        room: room_name, 
        id_message:msg.id_message, 
        access_token: s.user.access_token 
      });
    }
    else{

      
    }

    global.last_message_name=msg.username;
  }
}

function prepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

function drawMessage(msg,last_message_name, temporary=false){
  console.log("message",msg);
  var s=storage.get();
  // console.log("temp",temporary);
  var type="message-received"
  if(msg.username==s.user.username){
    type="message-sent";
  }

  jQuery('#'+msg.id_message).remove();

  jQuery('#chat_timeline').append(`
  <div id="${msg.id_message}" class="message ${type} message-tail ${last_message_name!=msg.username && type=='message-received'?'message-first':''}">
      <div> ${temporary?'<i class="fa fa-spinner fa-spin"></i>':''}</div>
      <div class="message-content">
          <!-- <div class="message-name">${msg.username}</div> -->
          <div class="message-bubble">
              <div class="message-text">${msg.message}</div>
          </div>
      </div>
  </div>
  `);

  document.getElementById('chat_timeline').scrollIntoView({ behavior: 'smooth', block: 'end' });
  jQuery('#f7.page-content')[0].scrollTop += 400;

}

export function allChats(){
  global.theme.render({
    "title": "Chat",
    "content": `
      <!--
        <button class="button fs-c-b fs-button button-large button-fill" id="new_chat">${t("Crea una nuova chat")}</button>
      -->

      <div class="list" style="display:none;">
        <ul>
          <li>
            <a class=" item-link smart-select smart-select-init" data-close-on-select="true" data-open-in="popup">
              <select id="choose_new_chat"></select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title"></div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>


      <div id="list_message" class="list media-list inset">
        <ul>
          <div style="text-align:center;">
            <img src="icons/loading.svg" style="width:70%;" id="loading_chat" />
          </div>
        </ul>
      </div>
    `,
    exclude_card:true
  });

  var s=storage.get();

  jQuery.ajax({
    contentType: "application/json; charset=utf-8",
    type: 'POST',
    url: global.chat_url+"api/v1/poderi/messages/home",
    data:JSON.stringify({
      uid: s.user.uid
    }),
    dataType: "json",
    success: function(res){
      global.chat_to_sync=false;

      var chats='';

      console.log(res);

      if(res.chat_messages.data.length==0){

        chats=`<li>
          <div class="item-content">
            <div class="item-inner">
              <h3>Non hai nessuna chat in corso</h3>
            </div>
          </div>
          </li>
          `;
      }
      else{

        var all_groups={};
        jQuery.each(res.chat_messages.data,function(k,v){
          if (!all_groups[v.id_group]) {
            console.log(v);
            all_groups[v.id_group]=v
          }
        });

        console.log(all_groups);
        s.chat={
          messages:res.chat_messages.data,
          groups:res.chat_root.data,
          empty_groups:res.empty_chat.data
        };
        storage.save(s);

        jQuery.each(all_groups,function(k,v){
          chats+=`
          <li>
            <a href="#chat/${v.id_group}" class="item-link link external item-content">
              <div class="item-media"><i class="f7-icons">${v.type=='direct'?'person_alt':'person_3_fill'}</i></div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="${v.is_read==0?'font-weight: 800;':'font-weight: unset;'}">${v.name}</div>
                  <div class="item-after"></div>
                </div>
                <div class="item-subtitle">${getMessageDate(v.timestmp)}</div>
                <div class="item-text ${v.is_read==0?'to_read':''}">${v.message_body} ${v.is_read==0?'<span class="badge_to_read badge"></span>':''}</div>
              </div>
            </a>
          </li>
          `;
        });

        // <button class="button fs-c-b fs-button button-large button-fill" onclick="location.hash='#advice/new'">Invia un nuovo messaggio</button>
        // <div class="block-title">Messaggi inviati</div>

        var new_chats='<option value="annulla" selected disabled><i>Annulla</i></option>';
        jQuery.each(s.chat.empty_groups,function(k,v){
          new_chats+=`<option value="${v.id_group}">${v.name}</option>`;
        });

        console.log(new_chats);
      }

      jQuery('#choose_new_chat').html(new_chats);
      jQuery('#list_message ul').html(chats);


      jQuery('#new_chat').click(function(){
        jQuery('#choose_new_chat').click();
      });

      jQuery('#choose_new_chat').change(function(){
        var val=jQuery(this).val();
        if (val!='annulla') {
          location.hash='chat/'+val;
        }
      });
      // setTimeout(function() {
      //   jQuery('.new_group').click();
      // }, 4000);
    }
  });




}

export function advice_home(){
  global.theme.render({
    "title": "Messaggi alle aziende",
    "content": `
      <button class="button fs-c-b fs-button button-large button-fill" onclick="location.hash='#advice/new'">Invia un nuovo messaggio</button>
    `,
    exclude_card:true
  });
}

function getMessageDate(date){
  var d=new Date(date);

  var day=d.getDate();
  var month_number=d.getMonth()+1;
  console.log(d.getMonth()+1);

  var month='';

  if (month_number==1) {
    month='gennaio';
  }
  else if (month_number==2) {
    month='febbraio';
  }
  else if (month_number==3) {
    month='marzo';
  }
  else if (month_number==4) {
    month='aprile';
  }
  else if (month_number==5) {
    month='maggio';
  }
  else if (month_number==6) {
    month='giugno';
  }
  else if (month_number==7) {
    month='luglio';
  }
  else if (month_number==8) {
    month='agosto';
  }
  else if (month_number==9) {
    month='settembre';
  }
  else if (month_number==10) {
    month='ottobre';
  }
  else if (month_number==11) {
    month='novembre';
  }
  else if (month_number==12) {
    month='dicembre';
  }

  var year='';
  if (d.getFullYear()!=(new Date).getFullYear()) {
    year=d.getFullYear();
  }

  var h = global.addZero(d.getHours());
  var m = global.addZero(d.getMinutes());
  var label=day+' '+month+' '+year+' '+h+':'+m;
  return label;
}

export function advice_new(){


  var all_farms= db.getAllFarms();

  var opt="<option value='all'>Tutti</option>";
  jQuery.each(all_farms, function(kf,f){
    opt+=`<option value="${f.id_farm_center}">${f.name}</option>`;
  });

  var roles=db.getRoles();
  var optr="<option value='all'>Tutti</option>";
  jQuery.each(roles, function(kf,f){
    optr+=`<option value="${f.id_role}">${f.role_name}</option>`;
  });



  global.theme.render({
    "title": "Messaggi alle aziende",
    "content": `
      <div>
        <div class="list inset">
          <ul>
            <li>
              <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
                <select id="send_to_type" multiple>
                  ${optr}
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">Tipo di utente</div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a class="item-link smart-select smart-select-init" data-open-in="popup" data-searchbar="true" data-searchbar-placeholder="Cerca">
                <select id="send_to_user" multiple>
                  ${opt}
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">Aziende</div>
                  </div>
                </div>
              </a>
            </li>

            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Messaggio</div>
                <div class="item-input-wrap">
                  <input id="the_message" type="text" placeholder="Il tuo messaggio" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Data ora dell'invio (lasciare vuoto per invio immediato</div>
                <div class="item-input-wrap">
                  <input  type="datetime-local" placeholder="Scegli..." />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Salva il gruppo per un uso futuro (non funziona)</div>
                <div class="item-input-wrap">
                  <input id="create_group" type="checkbox"  />
                </div>
              </div>
            </li>

          </ul>
        </div>

        <button id="send_message" class="button fs-c-b fs-button button-large button-fill">Invia</button>
      </div>


    `,
    exclude_card:true
  });

  jQuery('#send_message').click(function(){


    var message=jQuery('#the_message').val();
    var types=jQuery('#send_to_type').val();
    var users=jQuery('#send_to_user').val();


    // if(message.length==0){

    //   alert("Inserire il messaggio");
    // }
    // else if(types.length==0){

    //   alert("Scegli almeno una tipologia");
    // }
    // else if(users.length==0){

    //   alert("Scegli almeno una tipologia");
    // }
    // else{
    if(true){

      var s=storage.get();

      console.log("aaa");
      var chat_url = global.chat_url; //const socket = io(global.chat_url);
      // chat_url='http://localhost:9105';

      global.app.dialog.preloader("Invio in corso");

      jQuery.ajax({
        contentType: "application/json; charset=utf-8",
        type: 'POST',
        headers: {
          'Authorization': 'Bearer '+s.user.access_token,
          "Content-Type": "application/json",
        },
        url: chat_url+"api/v1/poderi/messages/send_message",
        data:JSON.stringify({
          types: types,
          users: users,
          sender_uid: s.user.uid,
          sender_username:s.user.username,
          message: message,
        }),
        dataType: "json",
        success: function(res){
          console.log(res);
          global.app.dialog.close();



          console.log(res);

          // location.hash='advice'
        },
        error: function(err){
          global.app.dialog.close();
          console.log(err.statusText);

        }
      });

    }




  });

}
