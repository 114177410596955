/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import * as tools from '../../tools.js';
import t from '../../translate.js';

export function init_db(){
  console.log("init");
}

export function storeImages(base_path){
  console.log("STORE IMAGES FS DATA.JS");

  if (typeof base_path=='undefined') {
    base_path=global.base_path+'monitoring_webpack/';
  }
  let settings=storage.get();



  let all_images={};
  jQuery.each(settings.my_images,function(k,v){
    jQuery.each(v,function(i,d){
      all_images[i]=d;
      all_images[i].guid_survey=k;
    });
  });
  let images=all_images;

  let to_upload={};
  console.log("Ci sono "+Object.keys(images).length+" immagini<p/>");
  jQuery.each(images,function(k2,v2) {
    if(v2.saved==false || v2.updated==false){
      to_upload[k2]=v2;
    }
  });
  console.log("Ci sono "+Object.keys(to_upload).length+" immagini da caricare<p/>");

  console.log("TO UPLOAD");
  console.log(to_upload);

  var cont=0;
  jQuery.each(to_upload,function(k,v) {
    console.log(v);
    cont++;
    try{
      console.log("provo a caricare "+k);
      var call_type='insert';
      if(v.update){
        call_type='update';
      }

      var base64=v.base64;
      if (base64=='') {


        //debugger;
        global.getFileContentAsBase64(v.imageUrl,function(base64Image){
          var guid=k;
          console.log(v);
          base64=base64Image;
          // console.log(v.imageUrl);
          // console.log(base64);
          // console.log(k);
          // console.log(guid);
          var urladdr = base_path+"store_images/"+call_type+"/"+guid;
          var headers={};
          var request={
            guid_survey:v.guid_survey,
            note:v.commento,
            base64:base64,
            access_token:settings.user.access_token,
          };
          if(global.plus){
            urladdr = global.base_call+"store_images/"+call_type+"/"+guid;
            request=JSON.stringify(request);
            headers={
              'Authorization': 'Bearer '+settings.user.access_token,
              "Content-Type": "application/json",
            };
          }
          jQuery.ajax({
            type: 'POST',
            headers:headers,
            // async:false,
            data:request,
            // contentType: "application/json",
            url: urladdr,
            dataType: "json",
            success: function(preverbale){
              console.log(" img "+guid+" "+preverbale.ok+"<p/>");
              updateImageStatus(guid, preverbale.ok);
            }
          });
        });
      }
      else {
        var guid=k;

        console.log("carico "+guid);
        var urladdr = base_path+"store_images/"+call_type+"/"+guid;
          var headers={};
          var request={
            guid_survey:v.guid_survey,
            note:v.commento,
            base64:base64,
            access_token:settings.user.access_token,
          };
          if(global.plus){
            urladdr = global.base_call+"store_images/"+call_type+"/"+guid;
            request=JSON.stringify(request);
            headers={
              'Authorization': 'Bearer '+settings.user.access_token,
              "Content-Type": "application/json",
            };
          }

        jQuery.ajax({
          type: 'POST',
          // async:false,
          headers:headers,
          // contentType: "application/json",
          data:request,
          url: urladdr,
          dataType: "json",
          success: function(preverbale){
            if (!preverbale.ok) {
              alert(JSON.stringify(preverbale));
            }
            console.log(" img "+guid+" "+preverbale.ok+"<p/>");
            console.log(guid +' caricata '+preverbale.ok);
            updateImageStatus(guid, preverbale.ok);
          }
        });
      }
    }
    catch(e){
      console.log(e);
      console.log(" Errore con verbale "+k+" <p/>");
    }


  });
}

function updateImageStatus(guid, ok){
  var s=storage.get();

  jQuery.each(s.my_images,function(i,d) {
    jQuery.each(d,function(k,v){
      if(k==guid){
        console.log(v);
        v.saved=ok;
        v.updated=ok;
      }
    });
  });

  storage.save(s);
}

export function lightSync(success,failure){


  jQuery("#sync_status").html("<i class='f7-icons'>arrow_right_arrow_left_circle_fill</i>");
  var s=storage.get();

  var access_token="";
  if(s.user){
    access_token=s.user.access_token;
  }

  var request={
    access_token:access_token,
  };
  var toSync={};
  // Vado a controllare quali tabelle devo iterare e contrllare se ci sono cose da sincronizzare
  var table_to_sync=getTableToSyncList();
  jQuery.each(table_to_sync,function(kt,table){
    //verifica se la tabella esiste
    if(s[table]){
      jQuery.each(s[table],function(k,v){
        if (v.sync==false) {
          if (!toSync[table]) {
            toSync[table]=[];
          }
          toSync[table].push(v);
        }
      });
    }
  });
  console.log("toSync",toSync);
  toSync.info={
    device_id:s.id_device,
    app_version:s.version
  }

  request.toSync=JSON.stringify(toSync);

  if(access_token!==''){

    var urladdr = global.base_path+"fairshare/fairshare_light_sync";
    var headers={};
    if(global.plus){
      urladdr = global.base_call+"fairshare_light_sync";
      headers={
        'Authorization': 'Bearer '+access_token,
        "Content-Type": "application/json",
      }
      request.toSync=toSync;
      request=JSON.stringify(request);
    }
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      headers:headers,
      data:request,
      // contentType: "application/json",
      dataType: "json",
      success: function(data){
        storeImages();
      
        if(global.nomeApp=='ATLAS'){
          var req={};
          if(data.manipulation){
            jQuery.each(data.manipulation,function(k,v){
              if(k=='field' || k=='farms' || k=='plot'){
                jQuery.each(v,function(k2,v2){
                  if(typeof req[k]=='undefined'){
                    req[k]={};
                  }
                  if(v2.store && v2.store['ok']){
                    if(v2.store.data){
                      req[k][k2]=v2.store.data;

                      if(v2.store.data.length>0 && v2.delete && v2.delete['ok']){
                        req[k][k2][0].deleted=true;
                      }

                      // if(v2.delete && v2.delete['ok']){
                      //   req[k][k2].deleted=true;
                      // }
                    }
                  }
                  // if(v2.delete && v2.delete['ok']){
                  //     req[k][k2]=[{"deleted":true}];
                  // }
                  console.log(k2);
                });
              }
            });
          }
          
          console.log("req",req);
          var s=storage.get();
          jQuery.ajax({
            type: 'POST',
            url: global.chat_url+"atlas/api/v1/send_notification",
            data:JSON.stringify(req),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            headers: {
              'Authorization':'Bearer '+s.user.social_token
            },
            success: function(res_notification){
              console.log(res_notification);
              if(res_notification.ok){
                console.log("notifiche inviate");
              }

            },
            error: function(res_notification){
              console.log(res_notification);
            }
          });

        }



        global.app.dialog.close();


        //Riprende lo storage
        var s=storage.get();

        var allOk=false;
        var all_message="";
        
        if (!data.nothing_to_sync) {
          if (data.manipulation) {
            allOk=true;
          }

          jQuery.each(data,function(k,v){
            if(k!='manipulation'){
              if(typeof v.ok!=='undefined'){
                allOk=allOk&&v.ok;

                if(!v.ok){
                  all_message+=k+",";
                }
              }
            }
          });

          //Itera le singole tabelle
          jQuery.each(data.manipulation,function(table,records){

            //Itera i singoli record della tabella
            jQuery.each(records,function(guid_updated, value){

              console.log(table, guid_updated, value);

              var ok_record=true;

              //Itera i singoli risultati del record e trova se sono tutti true
              //per un record ci possono essere n query store, delete etc...
              jQuery.each(value,function(k3, v3){
                if(!v3.ok){
                  ok_record=v3.ok;
                  allOk=false;
                }
              });

              //se record è stato aggiornato sul server va a cambiare il sync su settings
              if(ok_record){
                var found=false;

                //cerca tra tutti i record se esiste quello modificato
                jQuery.each(s[table], function(kk,rec){


                  var pk=get_pk(table);


                  //mette il record salvato
                  if(rec[pk]==guid_updated){
                    found=true;
                    rec.sync=true;
                  }

                });
                if(!found){

                  console.log("non ha trovato il record!");
                  //TODO la notifica va spostata nel success
                  global.app.notification.create({
                    icon: '<i class="f7-icons color-orange">exclamationmark_circle</i>',
                    title: t('Non ha trovato il record'),
                    titleRightText: t('Adesso'),
                    subtitle: '',
                    text: t("Errore nel salvataggio di")+" "+table,
                    closeTimeout: 2000,
                  }).open();
                }

              }
              else{
                console.log("Errore del server");
              }

            });
          });
        }



        //se qualcosa non ha sincronizzato lo segna
        if (!allOk) {
          console.log("Ci sono stati degli errori");
          jQuery("#sync_status").html("<i class='f7-icons color-red'>icon-hand_thumbsdown_fill</i>");

          global.app.notification.create({
            icon: '<i class="f7-icons color-orange">exclamationmark_circle</i>',
            title: t('Errore di sincronizzazione'),
            titleRightText: t('Adesso'),
            subtitle: '',
            text: t("Ci sono stati degli errori durante la sincronizzazione ("+all_message+")"),
            closeTimeout: 2000,
          }).open();

        }
        else {
          console.log("Ha salvato tutto");
          jQuery("#sync_status").html("<i class='f7-icons'>icon-hand_thumbsup_fill</i>");

          //TODO la notifica va spostata nel success
          global.app.notification.create({
            icon: '<i class="f7-icons">checkmark_circle</i>',
            title: t('Aggiornato'),
            titleRightText: t('Adesso'),
            subtitle: '',
            text: t("I dati sono stati salvati"),
            closeTimeout: 2000,
          }).open();

          if(success){
            success();
          }

        }

        storage.save(s);

        // jQuery.each(toSync,function(k,v){
        //     jQuery.each(v,function(k2,v2){
        //       sync_s=true;
        //       v2.sync=true;
        //     });
        //   });

        // if (sync_s) {
        //   console.log("NEW S",s);
        // storage.save(s);
        //
        // }
        // console.log("Tutto ok");






      },
      error: function(e){

        if(e.status==401){
          location.hash='login';
          console.log("Utete non loggato. Non si può sincronizzare")
          return;
        }

        console.log(e);
        jQuery("#sync_status").html("<i class='f7-icons color-red'>xmark_rectangle_fill</i>");

        global.app.dialog.close();

        console.log("ERRORE");

        console.log(e);
        // alert('Errore '+e.status+' per fairshare_sync')
        // if(typeof error=='function'){
        //   error(e);
        // }

        if (failure) {
          failure();
        }

      },
      timeout: 150000
    });

  }
  else{
    global.app.dialog.close();
    location.hash='login';
    console.log("Utete non loggato. Non si può sincronizzare")


    global.app.notification.create({
      icon: '<i class="f7-icons color-orange">exclamationmark_circle</i>',
      title: 'Errore di sincronizzazione',
      titleRightText: 'Adesso',
      subtitle: '',
      text: "Utete non loggato. Non si può sincronizzare",
      closeTimeout: 2000,
    }).open();
  }
}

//La funzione sincronizza i dati
//prima fa l'agiornamneto da app a web e poi riscarica i dati del web
export function sync(success, fail){

  var s=storage.get();

  var access_token="";
  if(s.user){
    access_token=s.user.access_token;
  }

  var request={
    access_token:access_token,
  };

  console.log("sync ....");

  var toSync={};
  // Vado a controllare quali tabelle devo iterare e contrllare se ci sono cose da sincronizzare
  var table_to_sync=getTableToSyncList();
  jQuery.each(table_to_sync,function(kt,table){
    //verifica se la tabella esiste
    if(s[table]){
      jQuery.each(s[table],function(k,v){
        if (v.sync==false) {
          if (!toSync[table]) {
            toSync[table]=[];
          }
          toSync[table].push(v);
        }
      });
    }
  });

  // if (JSON.stringify(toSync) !== JSON.stringify({})) {
    toSync.info={
      device_id:s.id_device,
      app_version:s.version
    }
  // }

  console.log("toSync",toSync);
  request.toSync=JSON.stringify(toSync);


  if(access_token!==''){

    var headers = {};
    var urladdr = global.base_path+"fairshare/fairshare_sync";
    if(global.plus){
      headers = {
        "Authorization": "Bearer "+access_token,
        "Content-Type": "application/json"
      };
      urladdr = global.base_call+"fairshare_sync";
      request=JSON.stringify(request);
    }

    jQuery.ajax({
      type: "POST",
      async:false,
      headers: headers,
      url: urladdr,
      data:request,
      // contentType: "application/json",
      dataType: "json",
      success: function(data){
        storeImages();

        // if()
        // if status code 401 redirect to login
        // console.log("success");
        // console.log(data);

        
        // debugger
        global.app.dialog.close();


        var allOk=true;

        jQuery.each(data,function(k,v){
          if (k!='manipulation') {
            allOk=v.ok&&allOk;
          }
        });

        if (allOk) {

          var all_tables=getTableToSyncList(true);
          jQuery.each(all_tables,function(at,table){
            
            if(data[table]){
              s[table]=data[table].data;
              jQuery.each(s[table],function(k,v){
                // Obersvation usa val, operation usa json
                if (v.json && (table!='observation')) {
                  var json;
                  if(typeof v.json=='string'){
                    json=JSON.parse(v.json);
                  }
                  else{
                    json=v.json;
                  }
                  // if (table=='field') {
                  //   // console.log(json.cultivar);
                  //   // console.log(v.cultivar);
                  // }

                  if (table!='operation') {
                    jQuery.each(json,function(j,e){
                      if (!(j in v) && j!='sync') {
                        v[j]=e;
                      }
                    });
                  }
                  else {
                    // operation ha bisongo dello stesso algoritmo di Obersvation ma su json invece che su val
                    json.sync=true;
                    s[table][k]=json;
                  }
                }
                else if (v.val && (table=='observation')) {
                  var val;
                  if(typeof v.val=='string'){
                    val=JSON.parse(v.val);
                  }
                  else{
                    val=v.val;
                  }
                  val.sync=true;
                  // if(!val.images){
                  // }
                  val.images=v.images;
                  s[table][k]=val;
                }
              });
            }
            else{
              // alert("Errore: "+table+" non presente in data");
              s[table]=[];
            }
          });

          console.log("SSSSS",s);
          storage.save(s);


          location.hash='';
        }
        else{
          // E' necessario rieffettuare il login
          alert("L'API fairshare_sync ha ricevuto un errore.. Riprova più tardi");
          console.log(data);
        }

      },
      error: function(e){
        global.app.dialog.close();

        if(e.status==401){
          location.hash='login';
          console.log("Utente non loggato. Non si può sincronizzare")
          return; 
        }

        console.log("ERRORE");

        global.app.dialog.close();
        console.log(e);
        alert('Errore '+e.status+' per fairshare_sync')
        if(typeof error=='function'){
          error(e);
        }
      },
      timeout: 30000
    });

  }
  else{
    global.app.dialog.close();
    location.hash='login';
    console.log("Utete non loggato. Non si può sincronizzare")
  }
}

//forse è meglio non mettere qui il localtio.hash?
export function insert_farm(farm){
  var s=storage.get();
  farm.sync=false;

  if (typeof s.farms=='undefined') {
    s.farms=[];
  }
  s.farms.push(farm);
  storage.save(s);

  lightSync();

  if (farm.farm_role=='advisor') {
    location.hash=`farms`;
  }
  else {
    location.hash=`farm/${farm.id_farm_center}`;
  }
}


export function  getZoo(id_farm_center,specie){
  let s=storage.get();

  //TODO dati di prova
  let animals=[];
  let recently_deleted=[];

  if (s.animal) {


    // animals=s.animal.filter(function(f){
    //   return f.id_farm_center==id_farm_center && f.specie==specie && !(f.toDelete);
    // });

    //itera gli animali 
    let len=s.animal.length;
    for(let i=0; i<len; i++){
      let v=s.animal[i];
      if (v.id_farm_center==id_farm_center && v.specie==specie && !(v.toDelete)) {
        if(v.type_out){
          recently_deleted.push(v);
        }
        else{
          animals.push(v);
        }
      }
    }

  }

  let breed=getBreeds(specie);
  

  return {"animal": animals, "breed": breed, "recently_deleted": recently_deleted};
}

export function getBreeds(specie){

  let s=storage.get();
  let breed=[];
  if (s.breed) {
    breed=s.breed.filter(function(f){return f.specie==specie});
  }
  return breed;

}

//TODO manage forms
export function getZooForm(specie){
  
  let s=storage.get();
  let animal_custom_form=[];
  if (s.animal_custom_form) {
    animal_custom_form=s.animal_custom_form;
  }
  return animal_custom_form;
  
}


//Save last_event_type
export function saveLastEventType(event_type){
  var s=storage.get();
  s.event_type=event_type;
  storage.save(s);
}

//Get last_event_type
export function getLastEventType(){
  var s=storage.get();
  return s.event_type;
}

export function updateFarm(id_farm_center, new_farm){


  //TODO usare la nuova funzione generica
  // updateElement("farm", "new_farm");


  new_farm.sync=false;

  // var old_farm=getFarm(id_farm_center);
  //
  // console.log("OLD",old_farm);
  // console.log("NEW",new_farm);

  var s=storage.get();
  jQuery.each(s.farms,function(k,v){
    if (v.id_farm_center==id_farm_center) {
      s.farms[k]=new_farm;
    }
  });

  storage.save(s);
  lightSync();

  //TODO la notifica va spostata nel success
  global.app.notification.create({
    icon: '<i class="f7-icons">checkmark_circle</i>',
    title: 'Aggiornato',
    titleRightText: 'Adesso',
    subtitle: '',
    text: "L'azienda è stata aggiornata correttamente",
    closeTimeout: 2000,
  }).open();

  if (new_farm.farm_role=='advisor') {
    // location.hash=`farms`;
    location.hash=`farm/`+id_farm_center;
  }
  else{
    location.hash=`farm/`+id_farm_center;
  }
}

//load a farm entity
export function getAllFarms(){
  var s=storage.get();
  return s.farms;
}

//load a farm entity
export function getFarm(id_farm_center){
  var s=storage.get();
  console.log(s);
  return s.farms.filter(function(f){return f.id_farm_center==id_farm_center})[0];
}

export function getCropOperation(id_crop_operation){
  var s=storage.get();
  var ss=[];

  if (s.crop_operation) {
    ss=s.crop_operation;
  }

  if (id_crop_operation) {
    ss=s.crop_operation.filter(function(s){return s.id_crop_operation==id_crop_operation})[0];
  }

  return ss;

}

export function resolveCropOperation(id_crop_operation){
  var crop_operation=getCropOperation(id_crop_operation);
  return crop_operation.name;
}

export function getSurveySchema(id_survey_schema){
  var s=storage.get();
  var ss=[];

  if (s.survey_schema) {
    ss=s.survey_schema;
  }

  if (id_survey_schema) {
    ss=s.survey_schema.filter(function(s){return s.id_survey_schema==id_survey_schema})[0];
  }

  return ss;
}

export function resolveSurveySchema(id_survey_schema){
  var ss=getSurveySchema(id_survey_schema);
  if (ss) {
    return ss.name;
  }
  else {
    return id_survey_schema;
  }
}

export function getCrop(id_crop){
  var s=storage.get();
  var crop=[];

  if (s.crop) {
    crop=s.crop;
    if (id_crop) {
      crop=s.crop.filter(function(f){return f.id_crop==id_crop})[0];
    }
    if(!crop.color){
      //random color
      crop.color='#'+Math.floor(Math.random()*16777215).toString(16);
      //crop.color='#000000';
    }
  }



  return crop;
}

export function getRoles(){
  return [
    {"id_role": "grower", "role_name": t("Proprietario")},
    {"id_role": "advisor", "role_name": t("Agronomo/Consulente")},
    {"id_role": "worker", "role_name": t("Dipendente")}
  ];
}



//Load all the entities connected with a farm
export function getFarmData(id_farm_center){

  let fd={};
  fd['crop']=getCrop();
  fd['season']=getSeasonsSorted(id_farm_center);
  fd['plot']=get_elements_filtered('plot', id_farm_center);
  fd['field']=get_elements_filtered('field', id_farm_center);
  fd['farm_center']=get_elements_filtered('farms', id_farm_center);
  fd['access']=get_elements_filtered('access', id_farm_center);
  fd['soil']=get_elements_filtered('soil', id_farm_center);
  fd['team']=get_elements_filtered('team', id_farm_center);
  fd['trap']=get_elements_filtered('trap', id_farm_center);
  return fd;
}

// Filtra i dati per azienda e stagione
export function getFarmDataSeason(id_farm_center,id_season){
  console.log(id_season);
let fd={};

  fd['season']=getSeasonsSorted(id_farm_center);

  if (id_season) {
    fd['actual_season']=get_elements_filtered('season',id_season,"id_season");
  }
  else {
    fd['actual_season']=getSeasonsSorted(id_farm_center);
    if (fd['actual_season'].length>0) {
      // id_season=fd['actual_season'][0].id_season
      id_season=fd.actual_season[0].id_season;
    }
    // else {
    //
    // }
  }
  fd['plot']=get_elements_filtered('plot', id_farm_center);
  fd['field']=get_elements_filtered('field', id_farm_center).filter(function(f){
    return f.id_season==id_season;
  });

  //elenco dei field per i filtri successivi
  let fields=fd['field'].map(function(f){return f.id_field});


  jQuery.each(fd['plot'],function(kp,p){
    let ff=fd.field.filter(function(f){
      return f.id_plot==p.id_plot;
    });

    //FIX, se il nome del campo maca ci mette il nome del plot
    if (ff.length>0) {

      if(!ff[0].name){
        ff[0].name=p.name;
      }
      p.field=ff[0];
      p.field.crop_name=getCropName(ff[0].id_crop);
    }
    else {
      if (p.field) {
        delete p.field;
      }
    }
  });

  fd['farm_center']=get_elements_filtered('farms', id_farm_center);
  fd['access']=get_elements_filtered('access', id_farm_center);
  fd['soil']=get_elements_filtered('soil', id_farm_center);
  fd['team']=get_elements_filtered('team', id_farm_center);

  fd['trap']=get_elements_filtered('trap', id_farm_center).filter(function(e){return e.id_season==id_season});
  fd['observation']=get_elements_filtered_fun('observation', function(f){
    return (fields.indexOf(f.id_field)>-1);
  });

  //per le operation basta il filtro su id_field, id_fields non va controllato, basta he ci sia il fiedl iniziale (che ci dovrebbe sempre essere)
  fd['operation']=get_elements_filtered_fun('operation', function(f){
    return (fields.indexOf(f.id_field)>-1);
  });
  return fd;
}



//get the soils of a farm centers, filtering the plot without soil or with the selected soil
export function getPlotForSoil(id_farm_center, id_soil){
  let plots=get_elements_filtered('plot', id_farm_center);
  return plots.filter(f=>(!f.soil || f.id_soil==id_soil) )
}


//insert a soil associated with 0 or n plots
export function insertSoil(soil, associated_plot, success){
  soil.sync=false;
  var s=storage.get();
  if(!s.soil){
    s.soil=[];
  }
  s.soil.push(soil);
  storage.save(s);
  lightSync();


  // updateAssociatePlot(associated_plot, soil);
  if(success){
    success();
  }
}



//update a Field
export function updateField(new_field, key, success){


  //aggiorna il suolo
  var found=updateElement("field", key, new_field);
  if(!found){
    alert("element not found!");
  }

  if(success){
    success();
  }

}

//update a Plot
export function updatePlot(new_plot, key, success){

  //aggiorna il suolo
  var found=updateElement("plot", key, new_plot);
  if(!found){
    alert("element not found!");
  }

  if(success){
    success();
  }

}

//insert a soil
export function updateSoil(new_soil, key, success){

  //aggiorna il suolo
  var found=updateElement("soil", key, new_soil);
  if(!found){
    alert("soil not found!");
  }
  if(success){
    success();
  }

}



//Load all the fields related data
export function getSoil(id_farm_center, id_soil){
  if(id_soil){
    return get_elements_filtered('soil', id_soil, "id_soil");
  }
  else{
    return get_elements_filtered('soil', id_farm_center);
  }
}


//Load all the fields related data
export function getPlotData(id_plot){

  var fd={};

  //Search plot object
  fd.plot=get_element_first('plot', id_plot, "id_plot");
  if(fd.plot){


    if(fd.plot.id_farm_center){
      fd.farm=get_element_first('farms', fd.plot.id_farm_center, "id_farm_center");
    }
    fd['season']=getSeasonsSorted(fd.plot.id_farm_center);
    fd['crop']=getCrop();

    //get all the fields associated to the plots
    fd.field=get_elements_filtered('field', id_plot, "id_plot");

    //find the right soil for the field
    var ff=findSoil(fd.plot.id_farm_center, fd.plot.id_plot);
    fd.soil=ff.soil;
    fd.available_soils=ff.available_soils;

  }


  return fd;


}

//Load all the fields related data
export function getFieldData(id_field){

    let ba=1;

    //Search for the field object
    let field=get_element_first('field', id_field, "id_field");
    let fd={};
    fd.field=field;


    if(fd.field.id_farm_center){
      fd.farm=get_element_first('farms', fd.field.id_farm_center, "id_farm_center");
    }

    //Decode season
    if(field.id_season){
      fd.season=get_element_first('season', field.id_season, "id_season");
    }
    //Decode crop
    if(field.id_crop){
      var cc=getCrop().filter(function(f){return f.id_crop==field.id_crop});
      if(cc.length>0){
        fd.crop=cc[0];
      }


      fd['aemodel']=get_elements_filtered('aemodel', field.id_crop, "id_crop");

      // debugger
      //TODO elimiare quando abbiamo sincronizzato i modelli
      if(field.id_crop==2){
        fd['aemodel']=[

          {
            "model_name": "dss_irrigation", "model_description": t("Irrigazione"),
            "section": "irri"
          },
          {
            "model_name": "dss_nutrition", "model_description": t("Concimazione"),
            "section": "nutri"
          },
          {
            "model_name": "aeco_olive_pheno", "model_description": t("Fenologia dell'olivo"),
            "section": "pheno"
          },
          {
            "model_name": "dss_olivefly_complete", "model_description": t("DSS Mosca dell'olivo"),
            "section": "pest"
          },
           {
             "model_name": "olive_bactrocera_mortality", "model_description": t(""),
             "section": "pest"
           },
          {
            "model_name": "xxx", "model_description": t(""),
            "section": "pest"
          }
        ]
      }
      else if (field.id_crop==1) {
        fd['aemodel']=[
          {
            "model_name": "dss_irrigation", "model_description": t("Irrigazione"),
            "section": "irri"
          },
          {
            "model_name": "dss_nutrition", "model_description": t("Concimazione"),
            "section": "nutri"
          },

          {
            "model_name": "multi_pest", "model_description": t(""), "inner_models":[
              {
                "model_name": "aeco_grape_lobesia", "model_description": t("Lobesia della vite"),
                "section": "moth"
              },
              {
                "model_name": "aeco_grape_powdery", "model_description": t("Oidio della vite"),
                "section": "powdery"
              },
              {
                "model_name": "aeco_grape_plasmo", "model_description": t("Plasmopara della vite"),
                "section": "downy"
              },
              {
                "model_name": "aeco_grape_ml", "model_description": t("Machine Learning"),
                "section": "ml"
              }
            ],
            "section": "pest"
          }

        ]
      }
      else if (field.id_crop==3) {
        fd['aemodel']=[
          {
            "model_name": "dss_irrigation", "model_description": t("Irrigazione"),
            "section": "irri"
          },
          {
            "model_name": "dss_nutrition", "model_description": t("Concimazione"),
            "section": "nutri"
          },
          {
            "model_name": "multi_pest", "model_description": t(""), "inner_models":[
              {
                "model_name": "aeco_corn_diabro", "model_description": t("Diabrotica virgifera"),
                "section": "diabro"
              },
              {
                "model_name": "aeco_corn_ostrinia", "model_description": t("Ostrinia nubilalis"),
                "section": "ostrinia"
              },
              {
                "model_name": "aeco_corn_heli", "model_description": t("Helicoverpa armigera"),
                "section": "heli"
              }
            ],
            "section": "pest"
          }
        ]
      }

      fd['aemodel_run']=get_elements_filtered('aemodel_run', field.id_field, "id_field");

    }


    //decode plot
    if(field.id_plot){
      fd.plot=get_element_first('plot', field.id_plot, "id_plot");
    }

    //find the right soil for the field
    var ff=findSoil(field.id_farm_center, field.id_plot);
    fd.soil=ff.soil;
    fd.available_soils=ff.available_soils;

    //get the observations and the operations

    fd['observation']=getFieldObservation(id_field);

    fd['operation']=getFieldOperations(id_field);


    fd['trap']=get_elements_filtered('trap', field.id_farm_center).filter(function(e){return e.id_season==field.id_season});

    return fd;
}


export function getModelFromCache(runDate, model_name, id_field){
  var model=[];
  var s=storage.get();
  if (s.model_cache) {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;

    // Elimino la cache con più di 24 ore...
    jQuery.each(s.model_cache,function(k,v){
      if (v) {
        var differenc=(new Date().getTime())-v.timestamp;

        if (differenc>day || !v.timestamp) {
          s.model_cache.splice(k, 1)
          console.log(differenc);
        }
      }

    });
    storage.save(s);

    var fd=getFieldData(id_field);
    var settings=getModelSettings(fd,model_name,runDate);
    // debugger
    var setting_hash=global.hashCode(JSON.stringify(settings));

    // Per usare la cache un modello deve avere lo stesso field, data, model_name e setting, altrimenti lo ricalcolo...
    model=s.model_cache.filter(function(f){
      return f.date==runDate && f.model_name==model_name && f.id_field==id_field && f.setting_hash==setting_hash;
    });
  }

  return model;
}

export function insertModelResult(runDate, model_name, id_field, setting_hash, response){
  var s=storage.get();

  if (!s.model_cache) {
    s.model_cache=[];
  }

  var new_model={
    date:runDate,
    setting_hash:setting_hash,
    model_name:model_name,
    id_field:id_field,
    response:response,
    timestamp:new Date().getTime()
  }

  s.model_cache.push(new_model);

  storage.save(s);

}

function findSoil(id_farm_center, id_plot){


  //Find the soils and filter default and assocted to plots
  var all_soils=get_elements_filtered('soil', id_farm_center, "id_farm_center");

  var available_soils=all_soils.filter(function(f){
    if(f.id_plots && f.id_plots.indexOf(id_plot)>-1){
      return true;
    }
    else if(f.is_default){
      return true;
    }
    else{
      return false;
    }
  });


  var soil=null;
  //esiste almeno 1 suolo valido
  if(available_soils.length>0){
    soil=available_soils[0];
  }

  if(available_soils.length>1){

    jQuery.each(available_soils,function(k,v){
      if (!v.is_default) {
        soil=available_soils[k];
        return false;
      }
    });
  }


  return {"soil": soil, "available_soils":available_soils}
}



export function getPreviousField(id_farm_center, id_plot, id_season){

  let previous_season=getPreviousSeason(id_farm_center, id_season);
  if(previous_season){
    let fields=get_elements_filtered('field', id_farm_center, "id_farm_center").filter(function(f){
      return f.id_plot==id_plot && f.id_season==previous_season.id_season;
    });

    let prev_field=null;
    if(fields.length>0){
      prev_field=fields[0];
    }

    if(prev_field){
      return prev_field;
    }
    else{
      return null;//{"ok" : false, "message" : "No previous field found"};
    }
  }
  else{
    return null; //{"ok" : false, "message" : "No previous season found"};
  }
}

//return the season sorted by year descending
export function getSeasonsSorted(id_farm_center){
  var s=get_elements_filtered('season', id_farm_center);

  // console.log("ss",s);
  var ssort=s.sort(function(a, b) {
    if(a.date_from < b.date_from) { return +1; }
    if(a.date_from > b.date_from) { return -1; }
    return 0;
  });


  // console.log("ss",ssort);
  return ssort;

}

//take the season id_season (otherwise return null)
export function getPreviousSeason(id_farm_center, id_season){
  let seasons=getSeasonsSorted(id_farm_center);
  let previous_season=null;
  jQuery.each(seasons,function(k,v){
    if (v.id_season==id_season) {
      if (seasons[k+1]) {
        previous_season=seasons[k+1];
      }
      return false;
    }
  });
  return previous_season;
}



//generic function to insert a record
export function insert_record(table, record, success){
  record.sync=false;
  var s=storage.get();
  if(!s[table]){
    s[table]=[];
  }
  s[table].push(record);
  storage.save(s);
  lightSync();

  if(success){
    success();
  }
}


//Generic function retrieve first element where key=id
export function get_element_first(element, id, key){
  var ff=get_elements_filtered(element, id, key)
  if(ff.length>0){
    return ff[0];
  }
  else{
    return {};
  }
}


//Funzione generica; verifica se un campo esiste
//se esiste lo filtra per id_farm_center, altrimenti forma un array vuoto
//di default cerca id_farm_center ma la key può cambiare
//Il filtro esclude sempre gli elementi cancellati
function get_elements_filtered(element, id, key){

  if(!key){
    key="id_farm_center";
  }

  var settings=storage.get();

  var s= jQuery.extend({}, settings);


  if(!s[element]){
    return [];
  }
  else{
    return s[element].filter(function(f){return f[key]==id && !(f.toDelete)});
  }
}


function get_elements_filtered_fun(element, fun){

  var settings=storage.get();

  var s= jQuery.extend({}, settings);


  if(!s[element]){
    return [];
  }
  else{
    return s[element].filter(fun).filter(function(f){return !(f.toDelete)});
  }

};

//delete an element
export function deleteElement(element, key, success){

  console.log("Delete campo "+element+" "+key);


  var s=storage.get();
  var pk=get_pk(element);

  var fff=s[element].filter(function(f){
    console.log(f,pk,key);
    return f[pk]==key;
  });

  if(fff.length>0){
    var field=fff[0];
    field.sync=false;
    field.toDelete=true;
    storage.save(s);
    lightSync();

    if(success){
      success();
    }

  }
  else{
    alert(`${element} with key ${key} not found!`);
  }


}


//update an element object; replase the old object with the value contained in the new_object
export function updateElement(element, key, new_object, success){
  //set the object as to be sincronize



  new_object.sync=false;
  var found=false;

  //by default the key is id_soil
  var primary_key=get_pk(element);

  var s=storage.get();
  jQuery.each(s[element],function(k,v){
    if (v[primary_key]==key ){
      var exiting_object=s[element][k];

      //merge new variables to the old one
      jQuery.each(new_object,function(i,d){
        exiting_object[i]=d;
      });
      s[element][k]=exiting_object;
      found=true;
    }
  });
  storage.save(s);
  lightSync();


  if(success){
    success();
  }

  return found;
}


//clone a season and treate all the fields associated
export function cloneSeason(id_farm_center, new_season, new_fields, success){

  //1. create a season
  var seas=insertSeasonFromYear(id_farm_center, new_season.year);
  seas.sync=false;

  //2. insert all the fields assigning the new id_season
  jQuery.each(new_fields, function(knf, nf){
    nf.id_season=seas.id_season;
    insertField(nf);
  });


  if(success){
    success(seas);
  }

  lightSync();

}

export function insertPlotAndField(new_plot, new_field, success){

  insert_record('plot',new_plot);

  //verifica se in season ha un guid o un oggetto (va creato il nuovo)
  if(typeof new_field.season=="object"){
    //crea il guid e mettilo al campo

    var seas=insertSeasonFromYear(new_field.id_farm_center, new_field.season.year);
    new_field.id_season=seas.id_season;
  }
  insert_record('field',new_field, success);

}


//Create a new trap and insert it in the database
export function insertTrap(trap, success){
  trap.sync=false;
  var s=storage.get();
  trap.id_trap=tools.getGuid();
  var s=storage.get();
  if (typeof s.trap=='undefined') {
    s.trap=[];
  }
  s.trap.push(trap);
  storage.save(s);
  lightSync();
  if(success){
    success();
  }
}



//update a trap
export function updateTrap(new_trap, key, success){

  new_trap.sync=false;
  var found=updateElement("trap", key, new_trap);
  if(!found){
    alert("trap not found!");
  }
  if(success){
    success();
  }
}

export function deleteTrap(key, success){
  deleteElement("trap", key, success);
}


export function getPest(){
  return [
    {"eppo": "dacol", "name": "Bactrocera olea"},
    {"eppo": "polybo", "name": "Lobesia botrana"},
    {"eppo": "carpo", "name": "Cydia pomonella"},
    {"eppo": "pyruu", "name": "Ostrinia nubilalis"},
    {"eppo": "diabvi", "name": "Diabrotica virgifera virgifera"},
    {"eppo": "heliar", "name": "Helicoverpa armigera"},
    {"eppo": "other", "name": t("Altro")}
  ]
}




export function decodePest(eppo){
  var p=getPest();
  var pest=p.filter(function(f){return f.eppo==eppo});
  if(pest.length>0){
    return pest[0].name;
  }
  else{
    return eppo;
  }
}


export function decodeTrap(id_trap){


  var trap=get_elements_filtered('trap', id_trap,"id_trap");
  if(trap.length>0){
    return trap[0].name;
  }
  else{
    return id_trap;
  }
}

//Function create a new plot and field (da togliere quando non si su apiù)
export function insertField(field_input, success){

  field_input.sync=false;

  //verifica se in season ha un guid o un oggetto (va creato il nuovo)
  if(typeof field_input.season=="object"){
    //crea il guid e mettilo al campo

    var seas=insertSeasonFromYear(field_input.id_farm_center, field_input.season.year);
    field_input.id_season=seas.id_season;

  }


  var s=storage.get();

  //verifica se in plot ha un guid o un oggetto (va creato il nuovo)
  var id_plot=field_input.id_plot;
  if(typeof field_input.plot=="object"){

    //crea il guid e mettilo al campo
    id_plot=tools.getGuid();
    field_input.id_plot=id_plot;
    var pl={
      id_plot:id_plot ,
      id_farm_center: field_input.id_farm_center,
      name: field_input.plot.name,
      superficie:field_input.superficie,
      sync: false
    }
    if (field_input.polygon_coordinates) {
      pl.polygon_coordinates=field_input.polygon_coordinates;
    }

    if (typeof s.plot=='undefined') {
      s.plot=[];
    }
    s.plot.push(pl)
  }

  if (typeof s.field=='undefined') {
    s.field=[];
  }
  s.field.push(field_input)

  storage.save(s);
  lightSync();

  if(success){
    success();
  }
}



export function updateFieldAndPlot(new_field, success){


  new_field.sync=false;

  var s=storage.get();
  jQuery.each(s.field,function(k,v){
    if (v.id_field==new_field.id_field) {
      // debugger
      s.field[k]=new_field;
    }
  });

  jQuery.each(s.plot,function(k,v){
    if (v.id_plot==new_field.id_plot) {
      s.plot[k].superficie=new_field.superficie;
      s.plot[k].polygon_coordinates=new_field.polygon_coordinates;
      s.plot[k].name=new_field.name;
      s.plot[k].sync=false;
    }
  });


  storage.save(s);
  lightSync();


  if (success) {
    success();
  }
}


//create a new season and save to the database
function insertSeasonFromYear(id_farm_center, year){

  var s=storage.get();
  var id_season= tools.getGuid();
  var seas={
    id_season: id_season,
    id_farm_center: id_farm_center,
    season_name: year,
    date_from: year+"-01-01",
    date_to: year+"-12-31",
    sync:false
  }
  if (typeof s.season=='undefined') {
    s.season=[];
  }
  s.season.push(seas)
  storage.save(s);
  lightSync();

  return seas;
}


//create a new season and save to the database
export function insertAccess(access){

  var s=storage.get();
  access.sync=false;

  if (typeof s.access=='undefined') {
    s.access=[];
  }
  s.access.push(access)

  storage.save(s);
  // Qui no perché faccio il controllo bloccante durante la richiesta
  return access;
}

export function deleteTeamMember(uid,id_farm_center){
  console.log("Delete uid:"+uid+" id_farm_center:"+id_farm_center);
  var s=storage.get();
  var team=s.team.filter(function(f){
    return f.uid==uid && f.id_farm_center==id_farm_center;
  })[0];
  team.sync=false;
  team.toDelete=true;

  storage.save(s);
  lightSync();

}

//Cancella l'accesso
export function deleteAccess(id_access){
  console.log("Delete",id_access);
  var s=storage.get();
  var access=s.access.filter(function(f){
    return f.id_access==id_access;
  })[0];
  access.sync=false;
  access.toDelete=true;

  storage.save(s);
  lightSync();

}

//Cancella il campo
export function deleteField(id_field){
  console.log("Delete campo",id_field);
  var s=storage.get();
  var field=s.field.filter(function(f){
    return f.id_field==id_field;
  })[0];
  field.sync=false;
  field.toDelete=true;

  storage.save(s);
  lightSync();

}




//Cancella il campo
export function deletePlot(id_plot, success){
  console.log("Delete plot",id_plot);
  var s=storage.get();
  var plot=s.plot.filter(function(f){
    return f.id_plot==id_plot;
  })[0];
  if(plot){
    plot.sync=false;
    plot.toDelete=true;

    storage.save(s);
    lightSync();
    if(success){
      success();
    }
  }
  else{
    global.app.notification.create({
      icon: '<i class="f7-icons color-orange">exclamationmark_circle</i>',
      title: t('Non ha trovato il record'),
      titleRightText: t('Adesso'),
      subtitle: '',
      text: t("Errore nella cancellazione del campo")+" "+id_plot,
      closeTimeout: 2000,
    }).open();
  }

}

export function deleteSoil(id_soil){
  console.log("Delete",id_soil);
  var s=storage.get();
  var ss=s.soil.filter(function(f){
    return f.id_soil==id_soil;
  });
  if(ss.length>0){
    var soil=ss[0];
    soil.sync=false;
    soil.toDelete=true;

    storage.save(s);
    lightSync();

  }
  else{
    alert("Il suolo non esiste")
  }
}

export function deleteFarm(id_farm_center){
  var c = confirm(t("Vuoi davvero eliminare l'azienda?"));
  if (c) {
    console.log("Delete",id_farm_center);
    var new_farm=getFarm(id_farm_center)
    new_farm.sync=false;
    new_farm.toDelete=true;

    updateFarm(id_farm_center, new_farm);

  }
}


export function insertOperation(id_field, operation, success){

  var s=storage.get();
  // var id_operation= tools.getGuid();
  // operation["id_operation"]=id_operation;
  operation["id_field"]=id_field;
  operation["sync"]=false;

  //create the structure if missing
  if (typeof s.operation=='undefined') {
    s.operation=[];
  }
  var found=updateElement("operation", operation.id_operation, operation);	
  if(!found){	
    s.operation.push(operation);	
    storage.save(s);	
    lightSync();	
  }	

  if(success){
    success(operation);
  }
  return operation;
}

export function updateOperation(id_operation,id_field, operation, success){
  //aggiorna le observations
  var found=updateElement("operation", id_operation, operation);
  if(!found){
    alert("operation not found!");
  }

  if(success){
    success();
  }
}

export function getOperation(id_operation){
  var s=storage.get();
  var oper={};
  if (s.operation) {
    oper=s.operation.filter(function(o){
      return o.id_operation==id_operation;
    })[0];
  }

  return oper
}


//Diego (questo va rivisto)
//create a new observation and save to the database
//the observations are grouped by type
export function insertObservation(id_field, observation, success){

  var s=storage.get();
  // var id_observation= tools.getGuid();
  // observation["id_observation"]=id_observation;
  observation["id_field"]=id_field;
  observation["sync"]=false;

  //create the structure if missing
  if (typeof s.observation=='undefined') {
    s.observation=[];
  }
  var found=updateElement("observation", observation.id_observation, observation);
  if(!found){
    s.observation.push(observation);
    storage.save(s);
    lightSync();
  }

  if(success){
    success(observation);
  }
  return observation;
}



export function updateObservation(id_observation,id_field, observation, success){
  //aggiorna le observations
  var found=updateElement("observation", id_observation, observation);
  if(!found){
    alert("observation not found!");
  }

  if(success){
    success();
  }
}

export function getObservation(id_observation){
  var s=storage.get();
  var obs={};
  if (s.observation) {
    if (id_observation) {
      obs=s.observation.filter(function(o){
        return o.id_observation==id_observation;
      })[0];
    }
    else {
      obs=s.observation;
    }
  }

  return obs
}


//the all the field observations;
//id_field: previously we were using id_field
//id_fields: the observation can be associated to more then 1 field
export function getFieldOperations(id_field){
  var s=storage.get();
  var obs=[];
  if (s.operation) {
    if (id_field) {
      obs=s.operation.filter(function(o){
        return !(o.toDelete) && (o.id_field==id_field || (o.id_fields && o.id_fields.indexOf(id_field)>-1  )) ;
      });
    }
  }

  return obs
}

//the all the field observations;
//id_field: previously we were using id_field
//id_fields: the observation can be associated to more then 1 field
export function getFieldObservation(id_field){
  var s=storage.get();
  var obs=[];
  if (s.observation) {
    if (id_field) {
      obs=s.observation.filter(function(o){
        return !(o.toDelete) && (o.id_field==id_field || (o.id_fields && o.id_fields.indexOf(id_field)>-1  )) ;
      });
    }
  }

  return obs
}


//produce la chiave primaria dalla tabella
//di norma è id_table ma farms fa eccezione
function get_pk(table){
  var pk="id_"+table;
  if(table=='farms'){
    pk='id_farm_center';
  }
  return pk;
}


//definiusce la tabelle da sincronizzare
//se all=true aggiunge anche le tabelle da caricare e basta
function getTableToSyncList(all){
  var table_to_sync=[
    "farms",
    "field",
    "plot",
    "season",
    "access",
    "team",
    "observation",
    "operation",
    "soil",
    "trap",
    "animal",
    "product"
  ];

  if (all) {
    table_to_sync.push('survey_schema');
    table_to_sync.push('crop_operation');
    table_to_sync.push('crop');
    table_to_sync.push('breed');
    table_to_sync.push('animal_custom_form');
  }
  return table_to_sync;
}


/* --------------------------------------
* Funzioni di export specifiche con id ghard coded
* ----------------------------- */

export function getFertilizer(){
  return [
    {"code":"MGA","name":"Acido fosforico","n":"","no3":"","nh4":"","urea":"","p2o5":54,"k2o":"","s":""},
    {"code":"AC","name":"Cloruro d'ammonio","n":25,"no3":"","nh4":25,"urea":"","p2o5":"","k2o":"","s":""},
    {"code":"MOP","name":"Cloruro di potassio","n":"","no3":"","nh4":"","urea":"","p2o5":"","k2o":60,"s":""},
    {"code":"DAP","name":"Fosfato biammonico","n":18,"no3":"","nh4":18,"urea":"","p2o5":46,"k2o":"","s":""},
    {"code":"DKP","name":"Fosfato bipotassico","n":"","no3":"","nh4":"","urea":"","p2o5":41,"k2o":54,"s":""},
    {"code":"MAP","name":"Fosfato monoammonico","n":12,"no3":"","nh4":12,"urea":"","p2o5":52,"k2o":"","s":""},
    {"code":"MKP","name":"Fosfato monopotassico","n":"","no3":"","nh4":"","urea":"","p2o5":52,"k2o":46,"s":""},
    {"code":"PR","name":"Fosforite","n":"","no3":"","nh4":"","urea":"","p2o5":30,"k2o":"","s":""},
    {"code":"CAN","name":"Nitrato ammonico calcareo","n":27,"no3":13.5,"nh4":13.5,"urea":"","p2o5":"","k2o":"","s":""},
    {"code":"AN26","name":"Nitrato di Ammonio 26 0 0","n":26,"no3":13,"nh4":13,"urea":"","p2o5":"","k2o":"","s":""},
    {"code":"AN35.5","name":"Nitrato di Ammonio 33.5 0 0","n":33.5,"no3":16.8,"nh4":16.8,"urea":"","p2o5":"","k2o":"","s":""},
    {"code":"NOP","name":"Nitrato di potassio 13 0 46","n":13,"no3":13,"nh4":"","urea":"","p2o5":"","k2o":46,"s":""},
    {"code":"SSP","name":"Perfosfato semplice","n":"","no3":"","nh4":"","urea":"","p2o5":19,"k2o":"","s":29},
    {"code":"TSP","name":"Perfosfato triplo","n":"","no3":"","nh4":"","urea":"","p2o5":46,"k2o":"","s":""},
    {"code":"K","name":"Polyhalite","n":"","no3":"","nh4":"","urea":"","p2o5":"","k2o":14,"s":48},
    {"code":"BS","name":"Scorie di Thomas","n":"","no3":"","nh4":"","urea":"","p2o5":14,"k2o":"","s":""},
    {"code":"AS","name":"Solfato ammonico","n":21,"no3":"","nh4":21,"urea":"","p2o5":"","k2o":"","s":60},
    {"code":"SOP","name":"Solfato di potassio","n":"","no3":"","nh4":"","urea":"","p2o5":"","k2o":50,"s":17},
    {"code":"UR","name":"Urea","n":46,"no3":"","nh4":"","urea":46,"p2o5":"","k2o":"","s":""},
    {"code":"NCA","name":"Nitrato di calcio","n":15.5,"no3":14.4,"nh4":1.1,"urea":"","p2o5":"","k2o":"","s":""},
    {"code":"MGN","name":"Nitrato di magnesio","n":"","no3":"","nh4":"","urea":"","p2o5":"","k2o":"","s":""},
    {"code":"NPK201010","name":"NPK 20-10-10","n":20,"no3":8.6,"nh4":11.4,"urea":"","p2o5":10,"k2o":10,"s":7.5},
    {"code":"NP2515","name":"Binario 25-15","n":25,"no3":11,"nh4":14,"urea":"","p2o5":15,"k2o":"","s":""},
    {"code":"UAS","name":"Urea Ammonio Solfato","n":40,"no3":36,"nh4":4,"urea":"","p2o5":"","k2o":"","s":12.4},
    {"code":"NP2510","name":"NP 25-10","n":25,"no3":11,"nh4":14,"urea":"","p2o5":10,"k2o":"","s":""}]
  ;
}
export function filter_irrigation(fd){
  return fd.operation.filter(function(f){
    return f.id_crop_operation==2;
  });
}
export function filter_ferti(fd){
  return fd.operation.filter(function(f){
    return f.id_crop_operation==3;
  });
}




export function filter_pheno(fd){
  //TODO 11 è solo per l'ulivo
  return  fd.observation.filter(function(f){
    return f.id_survey_schema==11;
  });
}



export function getModelSettings(fd,model_name,date_to){
  let fc=getFarmData(fd.field.id_farm_center);

  let lat=fc.farm_center[0].lat;
  let lon=fc.farm_center[0].lon;

  if (fd.plot.polygon_coordinates) {
    let coordinates;
    if (typeof fd.plot.polygon_coordinates=='string') {
      coordinates=JSON.parse(fd.plot.polygon_coordinates);
    }
    else {
      coordinates=fd.plot.polygon_coordinates;
    }
    lat=coordinates[0][0];
    lon=coordinates[0][1];
  }

  //TODO do we need the date_from???
  let anno=date_to.substring(0,4);

  //crop start and end
  let date_start_crop=anno+"-01-01";
  let date_end_crop=anno+"-12-31";


  let date_from=anno+"-01-01";

  if (model_name=='olive_bactrocera_glm' || model_name=='second_olive_bactrocera_glm') {
    date_from=(parseInt(anno)-1)+'-11-01';
  }
  else if (model_name=='dss_olivefly_complete' || model_name=='aeco_grape_ml' || model_name=='aeco_apple_erwinia' || model_name=='aeco_apple_bloom') {
    date_from=(parseInt(anno)-1)+'-10-01';
  }


  if (model_name=='dss_irrigation' && fd.field.id_crop==3) {
    jQuery.each(fd.operation,function(k,v){
      if (v.id_crop_operation==9) {
        date_from=v.date;
      }
    });
  }

  // IRRI LOG INZIO
  //todo mettere id_crop_operation parlante
  let irrigation_log=filter_irrigation(fd)


  let complete_irri_log=[];
  jQuery.each(irrigation_log,function(k,v){
    let log={};
    log.water=v.water;
    log.datetime=v.date;

    complete_irri_log.push(log);
  });
  // IRRI LOG FINE

  // ORGANIC FERTI LOG INZIO
  //todo mettere id_crop_operation parlante
  let organic_nutri_log=fd.operation.filter(function(f){
    return f.id_crop_operation==4;
  });


  //Prende il campo dell'anno precedente

  let prev_field=getPreviousField(fd.field.id_farm_center, fd.field.id_plot, fd.field.id_season);
  let previous_crop=null;
  if (prev_field) {
    prev_field=getFieldData(prev_field.id_field);

    let prev_organic_nutri_log=prev_field.operation.filter(function(f){
      return f.id_crop_operation==4;
    });

    jQuery.each(prev_organic_nutri_log,function(k,v){
      organic_nutri_log.push(v);
    });
    //TODO rivedere yield e straw_removed

    previous_crop={
      "name!!!!":prev_field.crop.name,
      "yield_ha": prev_field.field.yield_ha
    }

    //"previous_crop":{"name":"wheat", "date_start":"2012-12-31", "date_end":"2013-07-01", "date_burial": "2014-02-01","straw_removed":True, "yield_ha": 30}

  }

  let complete_organic_nutri_log=[];
  jQuery.each(organic_nutri_log,function(k,v){
    let log={};
    log.org_fert=v.fertilizzante;
    log.org_fert_amount=v.quantita;
    log.datetime=v.date;

    complete_organic_nutri_log.push(log);
  });
  // ORGANIC FERTI FINE

  // MINERAL FERTI LOG INZIO
  //todo mettere id_crop_operation parlante
  let mineral_nutri_log=fd.operation.filter(function(f){
    return f.id_crop_operation==3;
  });

  let complete_mineral_nutri_log=[];
  jQuery.each(mineral_nutri_log,function(k,v){
    // console.log(v);
    let log={};
    log.k2o=v.k2o;
    log.no3=v.no3;
    log.p2o5=v.p2o5;
    if(v.urea){
      log.urea=v.urea;
    }
    else{
      log.urea=0;
    }
    if(v.nh4){
      log.nh4=v.nh4;
    }
    else{
      log.nh4=0;
    }
    log.datetime=v.date;

    complete_mineral_nutri_log.push(log);
  });
  // MINERAL FERTI FINE

  // SOVESCIO LOG INZIO
  //todo mettere id_crop_operation parlante
  let sovescio_log=fd.operation.filter(function(f){
    return f.id_crop_operation==5;
  });

  let complete_sovescio_log=[];
  jQuery.each(sovescio_log,function(k,v){

    if(v.data_interramento && v.data_interramento!=''){
      let log={};
      log.crop=v.coltura;
      log.datetime=v.data_interramento;

      complete_sovescio_log.push(log);
    }
  });
  // SOVESCIO FINE

  // Potatura LOG INZIO
  //todo mettere id_crop_operation parlante
  let pruning=fd.operation.filter(function(f){
    return f.id_crop_operation==6;
  });

  //va a prendere
  //TODO dava errpre se mancavano le potature
  let pruning_set={};

  pruning_set.id='asportate';

  // debugger
  if (pruning.length>0) {
    pruning_set.id=pruning[pruning.length-1].potatura;
  }

  let auto_irrigation=false;
  if (fd.field.auto_irrigation) {
    auto_irrigation=fd.field.auto_irrigation;
  }


  let farmer={
      "green_manure": {"log": complete_sovescio_log},
      "organic_fertilizer": {"log": complete_organic_nutri_log},
      "pruning": pruning_set,
      "irrigation": {"auto_irrigate": auto_irrigation,"log": complete_irri_log},
      "fertilizer": {"log": complete_mineral_nutri_log}
  };

  if(previous_crop){
    farmer.previous_crop=previous_crop;
  }

  if (model_name=='aeco_grape_ml') {
    farmer={
      "perc_inf_downy":50,
      "perc_inf_gray":50,
      "perc_inf_powdery":50,
      "count_tr_0_7":0,
      "count_tr_7_14":0,
      "count_tr_14":0
    };
  }
  if (model_name=='dss_olivefly_complete') {
    farmer={
      "perc_inf_olivefly":43.6
    };
  }

  if (fd.field.data_ovideposizione) {
    farmer.olive_fly_monitoring= {
      "log":
      [
        {  "datetime": fd.field.data_ovideposizione, "olive_inf":1}
      ]
    };
  }


  if (model_name=='aeco_apple_erwinia') {
    farmer={
      "erwinia":"none_in_area"
    };
  }
  else if (model_name=='aeco_corn_diabro' || model_name=='aeco_corn_heli' || model_name=='aeco_corn_ostrinia') {
    farmer={};
  }

  // debugger
  var crop_name=getCrop(fd.field.id_crop).icon;
  if (!crop_name) {
    crop_name='';
  }

  var settings={
    "weather":[],
    "settings":{
      "param":{
        "id_field": fd.field.id_field,
        "date_from":date_from,
        "date_to": date_to
      }
    },
    "farmer":farmer,
    "crop":{
      "name": crop_name.toLowerCase(),
      "date_start":date_start_crop ,
      "date_end":date_end_crop
      // "vigour":"medium",
      // "yield_ha":"50",
      // "age":5
    }
  };

  let vigour='medium';
  let age=5;
  let yield_ha=50;

  if (fd.field.vigour) {
    vigour=fd.field.vigour;
  }
  if (fd.field.planting_year) {
    age=new Date().getFullYear()-parseInt(fd.field.planting_year);
  }
  if (fd.field.yield_ha) {
    yield_ha=fd.field.yield_ha;
  }

  // Todo: Serve? non lo so, prima era solo 2(olivo), ho messo anche il 3 per far funzionare il nutri su mais (Klean)
  if (fd.field.id_crop==1 || fd.field.id_crop==2 || fd.field.id_crop==3) {
    settings.crop.vigour=vigour;
    settings.crop.age=age;
    settings.crop.yield_ha=yield_ha;
  }

  if (fc.farm_center[0].id_station) {
    settings.settings.param.id_station=fc.farm_center[0].id_station;
  }
  else {
    settings.settings.param.lat=lat;
    settings.settings.param.lon=lon;
  }

  if(fd.soil){

    //Il modello gestisce i priofili del suolo. Sull'app gestiamo 1 unico strato di 1000mm  (dlayer)
    settings.soil={
      "layers": [
        {
          "clay": fd.soil.clay,
          "sand": fd.soil.sand,
          "n_soil": fd.soil.nutrient_level_n && fd.soil.nutrient_level_n!=''?fd.soil.nutrient_level_n:0.75,
          "p_soil": fd.soil.nutrient_level_p && fd.soil.nutrient_level_p!=''?fd.soil.nutrient_level_p:0.02,
          "k_soil": fd.soil.nutrient_level_k && fd.soil.nutrient_level_k!=''?fd.soil.nutrient_level_k:0.15,
          "ph": fd.soil.ph && fd.soil.ph!=''?fd.soil.ph:7,
          "dlayer": 1000,
          "soil_organic_matter": fd.soil.soil_organic_matter && fd.soil.soil_organic_matter!=''?fd.soil.soil_organic_matter:1.5,
          "cn": fd.soil.cn && fd.soil.cn!=''?fd.soil.cn:10
        }
      ] 
    }
  }

  return settings;
}

export function getCropName(id_crop){

  var ff=getCrop().filter(function(f){return f.id_crop==id_crop});

  if(ff.length>0){
    return t(ff[0].name);
  }
  else{
    return "-";
  }

}


export function decodeFasemedia(id_survey_schema, id_fase_media){
  var ss=getSurveySchema(id_survey_schema);
  // debugger
  if(ss && ss.schema && ss.schema.fasemedia && ss.schema.fasemedia.voc_val){
    var voc_val=ss.schema.fasemedia.voc_val;
    var nome_fase_media='';
    jQuery.each(voc_val, function(i, v){
      jQuery.each(v, function(ii, vv){
        // debugger
        if(parseInt(ii)==parseInt(id_fase_media)){
          console.log(ii);
          console.log(id_fase_media);
          console.log(vv);
          nome_fase_media=vv;
        }
      });
    });
  }
  return nome_fase_media;
}


export function getWarehouse(id_farm_center){

  let s=storage.get();

  let product=[];

  if(s.product){
    product = s.product.filter(function(w){return w.id_farm_center==id_farm_center});
  }

  return {
    "product":product, 
    "product_type": getProductType()
  }
  
}


export function getWarehouseOperation(id_farm_center){


  //let ware=getWarehouse(id_farm_center);
  let fields=get_elements_filtered('field', id_farm_center).map(function(f){return f.id_field});


  //per le operation basta il filtro su id_field, id_fields non va controllato, basta he ci sia il fiedl iniziale (che ci dovrebbe sempre essere)
  let operations=get_elements_filtered_fun('operation', function(f){
    return (fields.indexOf(f.id_field)>-1);
  });

  return operations;
}


export function getProductType(){

  return [
    { "id_product_type": "ferti", "name": "Fertilizzanti", "id_crop_operation": 3 },
    { "id_product_type": "pesti", "name": "Fitofarmaci" },
    { "id_product_type": "seed", "name": "Sementi", "id_crop_operation": 9  },
    { "id_product_type": "other", "name": "Altri" }
  ]

}