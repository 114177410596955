
import global from '../../global.js';
import * as tools from '../../tools.js';
import { validateEmail } from '../agroambiente/commonJS.js';
import * as db from './data.js';
import t from '../../translate.js';



export function show_soil(id_farm_center){
  jQuery('#footer').html('');

  var soils=db.getSoil(id_farm_center);
  global.theme.render({
    "title": t("Analisi dei suoli"),
    "content": `
    <button class="button fs-c-b fs-button button-large button-fill" onclick="location.hash='farm/${id_farm_center}/new_soil'">${t('Aggiungi suolo')}</button>

    <div class="card data-table">
      <table>
          <thead>
              <tr>
                  <th>${t('Apri')}</th><th>${t('Nome')}</th><th>Default</th><th>${t('Sabbia')}</th><th>${t('Argilla')}</th><th>${t('Elimina')}</th>
              </tr>
          </thead>
          <tbody id="soil_list"></tbody>
      </table>
    </div>
    `,
    exclude_card:true
  });

  jQuery.each(soils,function(ks,s){
    var tr=jQuery(`
      <tr>
          <td><button class="button" onclick="location.hash='farm/${id_farm_center}/soil/${s.id_soil}'"><i class="f7-icons">info_circle</i></button></td>
          <td>${s.sample_code}</td>
          <td>${s.is_default?'<i class="f7-icons">checkmark</i>':''}</td>
          <td>${s.sand}</td>
          <td>${s.clay}</td>
          <td><button class="button delete_soil"><i class="f7-icons">trash_circle_fill</i></button></td>
      </tr>
    `).appendTo("#soil_list").find(".delete_soil").on("click", function(){

      var c = confirm(t("Vuoi davvero eliminare l'analisi del suolo"));
      if (c) {
        db.deleteSoil(s.id_soil);

        //NOn fare location reload
        show_soil(id_farm_center);
      }
    });
  });
}

export function new_soil(id_farm_center){
  manageSoil(id_farm_center, null);
}

export function edit_soil(id_farm_center, id_soil){
  var soil=db.getSoil(id_farm_center, id_soil)[0];
  manageSoil(id_farm_center, soil);
}

//The function shows in a short for the soil asociated to a plot/field
export function showSoilShort(id_farm_center, soil, exclude_card, style_type){

  var soil_desc=`
    ${t('Assente')} - <a class="link external" href="#farm/${id_farm_center}/new_soil">${t('Aggiungi')}<i class="icon f7-icons">plus_app_fill</i></a>
  `;
  if(soil){
    soil_desc=`
      ${soil.sample_code} (${t('Sabbia')} ${soil.sand}% - ${t('Argilla')} ${soil.clay}%)
      <a class="link external" href="#farm/${id_farm_center}/soil/${soil.id_soil}"><i class="icon f7-icons">pencil</i></a>
    `;
  }

  if(exclude_card){
    if (style_type=='table') {
      var table=`
        <tr><th>${t('Suolo')}:</th><td>${t('Assente')}</td><td><a class="link external" href="#farm/${id_farm_center}/new_soil">${t('Aggiungi')}<i class="icon f7-icons">plus_app_fill</i></a></td></tr>
      `;
      if(soil){
        table=`
          <tr><th>${t('Suolo')}:</th><td>${soil.sample_code} (${t('Sabbia')} ${soil.sand}% - ${t('Argilla')} ${soil.clay}%)</td><td><a class="link external" href="#farm/${id_farm_center}/soil/${soil.id_soil}"><i class="icon f7-icons">pencil</i></a></td></tr>
        `;
      }
      return table;
    }
    else {
      return `
      <div><b>${t('Suolo')}</b>: ${soil_desc}</div>
      `;
    }
  }
  else{
    return `<div class="card card-outline">
    <div class="card-content card-content-padding">
      <b>${t('Suolo')}</b>: ${soil_desc}</div>
    </div>
    `;
  }
}

export function manageSoil(id_farm_center, soil){

  var title=t("Crea un nuovo suolo")+" 🧑‍🌾";
  var id_soil=null;
  if(soil){
    id_soil=soil.id_soil;
    title="Modifica il suolo";
  }

  global.theme.render({
    "title": title,
    "content": `
        <button id="standard_soil" class="button fs-c-b fs-button button-large button-fill">${t('Usa suolo standard')}</button>
        <div class="list inset" id="dbmng"></div>
        <div style="display:none" id="soil_list_plot">
          <div class="block-title">${t('Campi a cui il suolo è associato')}</div>
          <div class="list inset"><ul id="plot_list"></ul></div>
        </div>

        <div id="save_soil_message"></div>
    `,
    exclude_card:true
  });

  jQuery('#footer').html(`
    <button class="button fs-button button-large button-fill" id="save_soil">${t('Salva')}</button>
  `);

  if(soil && !soil.is_default){
    jQuery("#soil_list_plot").show();
  }

  var plots=db.getPlotForSoil(id_farm_center,null);
  jQuery.each(plots, function(kp,p){

    var checked="";

    //Check if the plots has the specific soils

    if(soil && soil.id_plots){
      if (typeof soil.id_plots=='string') {
        soil.id_plots=JSON.parse(soil.id_plots);
      }
      // debugger
      jQuery.each(soil.id_plots, function(kkp,pp){
        if(p.id_plot==pp){
          checked=`checked="checked"`;
        }
      });
    }

    jQuery(`
        <li>
        <label class="item-checkbox item-content">
            <input class="" type="checkbox" name="associated_plot" value="${p.id_plot}" ${checked}  />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
                <div class="item-title">${p.name}</div>
            </div>
            </label>
        </li>
    `).appendTo("#plot_list");
  });

  var sForm = new Dbmng.Form ({
    'aForm':{
      "fields":{
        "sample_code": {"label": t("Codice del Campione")},
        "sampling_date": {"label": t("Data di campionamento"),"widget":"date","type":"date"},
        "sand": {"label": t("Sabbia")+" %", "type": "float", "widget":"numeric", "nullable":0},
        "clay": {"label": t("Argilla")+" %", "type": "float", "widget":"numeric", "nullable":0},
        "soil_organic_matter": {"label": t("Sostanza Organica")+" %","placeholder":1.5, "widget":"numeric"},
        "nutrient_level_n": {"label": t("Azoto totale")+" ‰","placeholder":0.75, "widget":"numeric"},
        "nutrient_level_p": {"label": t("Fosforo")+" (P₂O₅) assimilabile ‰","placeholder":0.02, "widget":"numeric"},
        "nutrient_level_k": {"label": t("Potassio scambiabile")+" (K₂O) ‰","placeholder":0.15, "widget":"numeric"},
        "ph": {"label": "pH","placeholder":7, "widget":"numeric"},
        "cn": {"label": "C/N","placeholder":10, "widget":"numeric"},
        "is_default": {"label": t("Usa di default su tutti i campi"), "widget": "checkbox", "default": 1},
      }
    }
  });
  jQuery('#dbmng').append(sForm.createForm(soil));

  jQuery(".dbmng_form_field_is_default input").on("change", function(){
    var is_checked=jQuery(this).prop("checked");
    if(!is_checked){
      jQuery("#soil_list_plot").show();
    }
    else{
      jQuery("#soil_list_plot").hide();
    }
  });

  jQuery("#standard_soil").on("click", function(){

    jQuery('#dbmng').html(sForm.createForm({
      "sample_code": "Standard",
      "sand": 40,
      "clay": 20,
      "soil_organic_matter": 1.5,
      "nutrient_level_n":0.75,
      "nutrient_level_p":0.02,
      "nutrient_level_k":0.15,
      "ph":7,
      "cn":10,
      "is_default":1
    }));

    jQuery('#save_soil').click();
  });

  // Controllo un po' brutto per controllare che la somma di sabbia e argilla non superi qutoa 100
  var total_texiture=0;
  jQuery('#dbmng .real_widget').change(function(){
    var val=jQuery(this).val();
    var who=jQuery(this).parent().siblings().text();
    if ((who=='Sabbia' || who=='Sand') || (who=='Argilla' || who=='Clay') ) {
      var other_who='';
      var other_val=0;
      if (who=='Sabbia' || who=='Sand') {
        other_who='Argilla';
      }
      else if (who=='Argilla' || who=='Clay') {
        other_who='Sabbia';
      }

      jQuery.each(jQuery('#dbmng .item-title.item-label'),function(k,v){
        if (jQuery(v).text()==other_who) {
          other_val=jQuery(v).parent().find('.real_widget').val()
        }
      });

      // debugger
      var tot=parseInt(val)+parseInt(other_val!=''?other_val:0);
      console.log("Total texiture: "+tot);

      total_texiture=tot;
      if (total_texiture>100) {
        alert(t('Attenzione. La somma di sabbia e argilla non può superare 100'));
      }
    }
  })



  jQuery("#save_soil").on("click", function(){

      console.log("save");
      var iv=sForm.isValid();
      if(iv.ok){
        var new_soil=sForm.getValue();

        if ((new_soil.sand+new_soil.clay)<=100) {
          new_soil.id_farm_center=id_farm_center;
          var associated_plot=[];
          jQuery("input[name='associated_plot']:checked").each(function(kp,p){
            associated_plot.push(jQuery(p).val());
          });

          new_soil.id_plots=associated_plot;



          //decide se inserire o aggiornare
          if(!soil){
            //se si inserisce si definisce la chiave
            new_soil.id_soil=tools.getGuid();
            db.insertSoil(new_soil, associated_plot);
          }
          else{

            db.updateSoil(new_soil, soil.id_soil);
          }
          //deve tornare da dove viene
          history.back();
          //location.hash='farm/'+id_farm_center+"/soil";
        }
        else {
          alert(t('Attenzione. La somma di sabbia e argilla non può superare 100'));
        }
      }
      else{
        jQuery("#save_soil_message").html(iv.message);
      }

  });

}
