
import * as db from './data.js';
import t from '../../translate.js';
import global from '../../global.js';
import storage from '../../storage.js';
import * as tools from '../../tools.js';
// import *  as fs_lib from './fairshare.js';
// import photo from '../../core/photo.js';
// import * as fairshare_oo from './fairshare_oo.js';

export function warehouse_home(id_farm_center) {

  console.log("warehouse_home");

  global.theme.render({
    "title": t("Magazzino Aziendale"),
    "content": `      
      <div id="warehouse_list" class=""></div>
    `,
    exclude_card:true
  });


  let ware=db.getWarehouse(id_farm_center);


  let ware_op=db.getWarehouseOperation(id_farm_center);


  let operats={};
  jQuery.each(ware_op, function(k,op){

    if(op.products){

      jQuery.each(op.products, function(k2,p2){
        let id_product=p2.id_product;
        if(!p2.id_product){
          id_product=op.id_crop_operation+"_nocode";
        }
        if(!operats[id_product]){
          operats[id_product]=[];
        }
        p2.date=op.date;
        operats[id_product].push(p2);
      });
    }
    //old version if products does not exists
    else{
      let id_product=op.id_product;
      if(!op.id_product){
        id_product=op.id_crop_operation+"_nocode";
      }
      if(!operats[id_product]){
        operats[id_product]=[];
      }
      operats[id_product].push(op);
    }
  });


  console.log(ware);

  if(false){ //ware.product.length==0){
    jQuery("#warehouse_list").html("Non ci sono prodotti in magazzino")
  }
  else{
    jQuery.each(ware.product_type, function(k, tp){
    console.log(tp  );
      let pd=ware.product.filter(function (f){
        return f.id_product_type==tp.id_product_type
      });
      
      var html='';
        //title o the left and button o the right
        html+=(`
          <div class="block block-strong inset">
            <div class="block-title">
              <div style="float:left">
                <h3>${tp.name}</h3>
              </div>
              <div style="float:right">
                <a href="#/farm/${id_farm_center}/warehouse/new/${tp.id_product_type}" class="link external">
                  <i class="f7-icons">plus</i>
                </a>
              </div>
            </div>          
        `);//.appendTo("#warehouse_list");

        if(pd.length==0){

          html+=(`
            <div class="block-footer">
              Non ci sono ${tp.name} in magazzino            
            </div>
          `)

          html+=(`
            </div>          
        `);//.appendTo("#warehouse_list");

        // debugger
         jQuery("#warehouse_list").append(html);

        }
        else{
          console.log(k);
          html+=(`
            <div class="data-table">
              <table class="table">
                <thead><tr>
                  <th>Nome</th><th>Ingresso</th><th>Quantità</th><th>Residuo</th>
                </tr></thead>
                <tbody class="prod_${k}">
                </tbody>
              </table>
            </div>       
          `);//.appendTo("#warehouse_list");
            

        
        html+=(`
            </div>          
        `);//.appendTo("#warehouse_list");

        
        let sec=jQuery("#warehouse_list").append(html);

        jQuery.each(pd, function(k2,p2){

          let operat=operats[p2.id_product];
          let status="<span class='badge color-green'>Disponibile</span>";
          let residual=p2.quantity;
          let date_ok=true;


          let labe="";

          
          if(operat){
            let sum_amount=0;
            
            jQuery.each(operat, function(k3,op){

              console.log(op);
              if(op.date<p2.date){
                date_ok=false;
              }
              sum_amount+=parseFloat(op.amount_tot);

              console.log("op",op,op.amount_tot);

              labe=labe+op.date+": "+op.amount_tot+"<br>";
            });


            


            residual=parseFloat(p2.quantity)-sum_amount;

            status="<span class='badge color-light-green'>Disponibile (usato)</span>";
            if(residual==0){
              status="<span class='badge color-orange'>Esaurito</span>";
            }
            if(residual<0){
              status="<span class='badge color-red'>Troppo Consumo</span>";
            }
          }


          


          if(!date_ok){
            status=status+"<span class='badge color-red'>Data non corretta</span>";
          }


          let tr=(`
            <tr>
              <td><a 
                class="link external" 
                href="#/farm/${id_farm_center}/warehouse/edit/${tp.id_product_type}/${p2.id_product}">
                  ${p2.name} - ${p2.code_product!=0? p2.code_product : "(inser. manuale)"}
              </a></td>
              <td>${p2.date_enter}</td>
              <td>${p2.quantity}</td>
              <td>${parseFloat(residual).toFixed(2)}</td>
              <td class="status_div" title="${labe}">${status} </td>
            </tr>
          `);
            

          // sec.find(".status_div").tooltip({
          //   theme:"light",
          //   trigger:"hover",
          //   delay: 500,
          //   html:true
          // });

          sec.find("tbody.prod_"+k).append(tr);
          // debugger

        });        
      }

      
    });
  }


  jQuery.each(ware.product_type,function(i,product){

    jQuery("#add_product").append(`
      <button class="btn btn-primary" onclick="location.hash='#farm/${id_farm_center}/warehouse/new/${product.id_product_type}'">
        ${product.name}
      </button>
    `);

  });



}

export function warehouse_new(id_farm_center,id_product_type) {
  warehouse_upsert(id_farm_center,id_product_type,null);
}


export function warehouse_edit(id_farm_center, id_product_type, id_product){
  warehouse_upsert(id_farm_center,id_product_type,id_product);
}


export function warehouse_upsert(id_farm_center,id_product_type, id_product) {

  console.log("warehouse_new");

  let pt=db.getProductType();

  let product=pt.filter(function(p){
    return p.id_product_type==id_product_type;
  });

  let prod_tip="Sconosciuto";
  if(product.length>0){
    prod_tip=product[0].name;
  }


  global.theme.render({
    "title": t("Aggiungi prodotto"+"-"+prod_tip),
    "content": `
      <div id="add_product" class=""></div>
    `,
    exclude_card:true
  });


  let product_type=db.getProductType(id_product_type);


  let oc=`
    <option value=''>Nessun prodotto trovato</option>
  `;
  let select_label="cerca il prodotto";
  let default_opt="";

  // <div class="list inset"><ul ><li >
  //     <a class="item-link smart-select smart-select-init" data-open-in="popup"  data-close-on-select="true" data-searchbar="true">
  //       <select id="search_product">${oc}</select>
  //       <div class="item-content" style="">
  //         <div class="item-inner" style="">
  //           <div class="item-title">${select_label}</div>
  //           <div class="item-after" style="">${t(default_opt)}</div>
  //         </div>
  //       </div>
  //     </a>
  //   </li></ul></div>

  jQuery("#add_product").html(`  
    <div id="custom_form" class="list inset"></div>  
    <div id="form_res"></div>
  `);

  jQuery("#footer").html(`
    <button class="button fs-button button-large button-fill" id="add_product_btn">${t("Salva")}</button>
  `);
      

  let theme_f7=new Dbmng.Framework7Theme();

  let today = new Date().toISOString().substr(0, 10);

  //"placeholder": "inserisci alcuni caratteri per cercare"
  let f={
    "fields": {
      "code_product": {"label": "code_product", "widget": "hidden"},
      "name": {"label": t("Prodotto"), "type": "text", "nullable": 0,  "default": product_type.name}, 
      "date_enter": {"label": t("Data entrata"), 
        "type": "date", "widget": "date" , 
        "nullable": 0, "default": today
      },
      "uom": {
        "label": t("Unità di misura"), 
        "type": "text", "widget":"select", "nullable": 0,
        "default": "kg",
        "voc_val": [
          {"kg": "Kg"},
          {"q": "q"},
          {"g": "g"},
          {"l": "L"},
          {"n": "N°"}
        ]
      },
      "quantity": {"label": t("Quantità"), "type": "float", "widget": "numeric" , "nullable": 0},
      "supplier": {"label": t("Fornitore"), "type": "text", "nullable": 1},
      "invoice": {"label": t("Fattura/Bolla"), "type": "text", "nullable": 1},
      "note": {"label": t("Note"), "type": "text", "nullable": 1}        
    }
  };

  if(id_product_type=='ferti'){
    f.fields.n={"label": t("Azoto"), "type":"numeric", "widget":"numeric"};
    f.fields.p={"label": t("Fosforo"), "type":"numeric", "widget":"numeric"};
    f.fields.k={"label": t("Potassio"), "type":"numeric", "widget":"numeric"};
  }
  // else if(id_product_type=='pesti'){
  //   f.fields.n={
  //     "label": t("Motivo"),  "widget":"dbmng",
  //     "dbmng":{
  //       "crop": {"label": "crop"}
  //     }
  //   };
  // }

  let existing_value=null;
  if(id_product){
    existing_value=db.get_element_first("product", id_product, "id_product");
  }

  let oForm = new Dbmng.Form ({'aForm':f, theme:theme_f7});
  jQuery('#custom_form')[0].appendChild(oForm.createForm(existing_value));

  let reasonForm=null;

  if(id_product_type=='pesti'){

    //add a form crudinline for crop
    let crop_form={
      "table": "pesti_crop",
      "primary_key": ["id_pesti_crop"],
      "fields": {
        "id_pesti_crop": {"label": "id_crop", "widget": "hidden"},
        "id_crop": {"label": "Coltura", "widget": "select"},
        "pest": {"label": t("Avversita"), "type": "text", "nullable": 0},
      }
    };

    let crop=db.getCrop();
    let crop_list=[];
    for(let i=0;i<crop.length;i++){
      let o={};
      o[crop[i].id_crop]=crop[i].name;
      crop_list.push(o);
    }
    crop_form.fields.id_crop.voc_val=crop_list;


    reasonForm=new Dbmng.FormInline({aForm:crop_form, theme:theme_f7, aParam:{
      'del_label':'Canc',
      'hide_label': true, 
      'do_insert': true, 'do_delete': true
    }});
    // console.log(survey[kfield]);

    let ex_reason=null;
    if(existing_value){
      ex_reason=existing_value.reason;
    }

    jQuery('#custom_form')[0].appendChild((reasonForm.createForm(ex_reason)));
  }


  jQuery("#custom_form .dbmng_form_field_name").click(function(){


    console.log("click");


    //Create sheet modal
    var dynamicSheet = global.app.sheet.create({
      closeByOutsideClick: true,
      content: `
        <div class="sheet-modal">
          <!-- add search box -->
          <!-- add close button -->
          <div class="sheet-modal-inner">
            <div class="page-content">
              <div class="block">
                
                <p>Scegli il prodotto</p>
                <p><a href="#" class="sheet-close">Close me</a></p>
                <!-- Scrollable sheet content -->


                <div class="list">
                  <ul>
                    <li class="item-content item-input">

                    <div class="searchbar searchbar-inline col-sm-12" style="width:100%">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Inizia a digitare alcune lettere per cercare nell'archivio online">
                        <i class="searchbar-icon"></i>
                        <span class="input-clear-button"></span>

                        <div class="searchbar-backdrop "></div>
                        <div class="searchbar-overlay ">
                          <div id="search_result" style="width:100%" class="list list-strong-ios list-outline-ios list-dividers-ios search-list searchbar-found">
                            <ul>
                              <li class="item-content">
                              </li>
                            </ul>

                          </div>

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
                      


      `.trim(),
    });

    // Events also can be assigned on Sheet instance later
    dynamicSheet.on('open', function () {
      console.log('Sheet open');
    }
    );

    // open it
    dynamicSheet.open();

    var searchBar = global.app.searchbar.create({
      el: '.searchbar',
      customSearch: true,
      on: {
          search: function(sa) {
              

              let search_text=sa.query;


              console.log('Searching', search_text);
              let s=storage.get();
              let urladdr = global.base_call+"search_product/"+id_product_type+"/"+search_text+"";
              let headers = {
                'Authorization': 'Bearer '+s.user.access_token,
                'Content-Type': 'application/json'
              }

              if(search_text.length>2 ){

                jQuery.ajax({
                  url: urladdr,
                  type: 'GET',
                  headers: headers,
                  success: function(resu) {
                    console.log(resu);

                    if(resu.ok){

                      jQuery("#search_result ul").html("");

                      

                      jQuery.each(resu.data, function( index, value ) {

                      let stato="";

                      if(value.stato_amministrativo=="Revocato"){
                        //danger badge in f7
                        stato="<badge class='badge badge-danger'>"+value.stato_amministrativo+"</badge>"; 
                      }
                      else {
                        stato=value.stato_amministrativo;
                      }

                      let altro="";
                      let fd="";
                      if(id_product_type=="ferti"){

                        if(value.n!==null){
                          altro=""+value.n+"-"+value.p+"-"+value.k+"";
                        }
                        fd=" data-n='"+value.n+"' data-p='"+value.p+"' data-k='"+value.k+"' ";
                      }
                      else{
                        if(value.concentrazione!==null){
                          altro=value.scadenza_autorizzazione;
                        }
                      }

                      jQuery("#search_result ul").append(`
                        <li class="">
                          <label class="item-radio item-radio-icon-start item-content">
                            <input type="radio" name="radio-product" ${fd} data-label="${value.name}" value="${value.id}" checked="">
                              <i class="icon icon-radio"></i>
                              <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">${value.name}</div>
                                <div class="item-after">${value.company}</div>
                              </div>
                              <div class="item-subtitle"> ${stato} - ${altro}</div>
                            </div>
                                
                            </input>
                          </label>
                        </li>
                        `);
                      });


                      jQuery("#search_result ul").append(`
                        <li class="">
                          <label class="item-radio item-radio-icon-start item-content">
                            <input type="radio" name="radio-product"  data-label="${search_text}" value="0" checked="">
                              <i class="icon icon-radio"></i>
                              <div class="item-inner">
                              <div class="item-title-row">
                                Usa il tuo testo: ${search_text}                                
                              </div>
                            </div>
                                
                            </input>
                          </label>
                        </li>
                      `);
                      

                      //Click on item
                      jQuery("#search_result ul li").click(function(){
                        console.log("click");
                        let inp=jQuery(this).find("input");
                        let code_product=inp.val();
                        let label=inp.attr("data-label");
                        jQuery(".dbmng_form_field_name input").val(label);
                        jQuery(".dbmng_form_field_code_product input").val(code_product);

                        if(inp.attr("data-n")!=null){
                          jQuery(".dbmng_form_field_n input").val(inp.attr("data-n"));
                          jQuery(".dbmng_form_field_p input").val(inp.attr("data-p"));
                          jQuery(".dbmng_form_field_k input").val(inp.attr("data-k"));

                          jQuery("#form_res").html(`
                            Attenzione! controlla i dati di PK sono corretti sono stati estratti automaticamente
                          `);
                        }
                        
                        dynamicSheet.close();
                      });

                    }
                    else{
                      //alert("Errore nella ricerca. Riprovare. "+resu.message)
                      console.log("Errore nella ricerca. Riprovare. "+resu.message)
                    }
                  },
                  error: function(resu) {

                    //get error code
                    if(resu.status==401){
                      alert("Errore nella ricerca. Controllare se sei loggato e riprovare")
                    }else{
                      alert("Errore nella ricerca. Riprovare")
                    }
                  }
                });

              };


          },
          clear: function(s) {
              console.log('Clearing', s);
          }
      }
    });
  });



  jQuery("#add_product_btn").on("click",function(){
    console.log("add_product_btn");

    let is_valid=oForm.isValid();
    if(is_valid["ok"]){

      let value=oForm.getValue();
      value.id_farm_center=id_farm_center;
      value.id_product_type=id_product_type;
      if(id_product){
        value.id_product=id_product;
      }
      else{
        value.id_product=tools.getGuid();
      }

      if(reasonForm!=null){
        value.reason=reasonForm.getValue();
      }

      if(id_product){
        db.updateElement("product", id_product, value);
      }
      else{ 
        db.insert_record("product", value);
      }
      history.back();
      db.storeImages();
  

    }
    else{
      jQuery("#form_res").html(`
        <div class="alert alert-danger" role="alert">
          ${is_valid["message"]}
        </div>
      `)
    }
  });

}